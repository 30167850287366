import errorIllustration from "../../assets/images/404.svg";
import { useTranslation } from "react-i18next";

function ErrorPage() {
  const { t } = useTranslation();

  return (
    <div className="h-full flex-1 flex flex-col items-center justify-center">
      <img src={errorIllustration} alt="erreur 404" className="w-4/5 h-4/5" />
      <h1 className="text-xl font-hairline">{t("commons.error")} </h1>
      <p className="text-base">{t("commons.notFound")}</p>
    </div>
  );
}

export default ErrorPage;
