import axios from "./axios";

export const uploadMediaFiles = (mydata) => {
  return axios
    .post(`/connect/profile/upload-picture/`, mydata)
    .then((responses) => {
      if (responses.status === 201) return responses.data;
    })
    .catch((errors) => errors.response);
};
