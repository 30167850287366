import { Switch, Route } from "react-router-dom";
import Home from "../pages/home/Home";
import { ErrorPage } from "../components";

export default function HomeNavigation() {
  let routes = (
    <Switch>
      <Route exact path={`/`} render={() => <Home />} />

      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  );

  return routes;
}
