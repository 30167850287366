import React, { useState } from "react";
import { Button, Form, InputText, Space, Text } from "../../components/";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { signin, getUserInformations } from "../../utils/services/";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/landing";
import { useTranslation } from "react-i18next";

export default function Signin() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" }); //form validation
  const { t } = useTranslation();
  const history = useHistory();

  const [loginIn, setLoginin] = useState(false);

  const dispatch = useDispatch();
  // GET LANGUAGE TO CHANGE TEXT-DIRECTION
  const { language } = useSelector((state) => state.language);

  const onSubmit = async (data) => {
    try {
      setLoginin(true);
      await signin(data, (x) => {
        return t(x);
      });
      await getUserInformations(dispatch);
      setLoginin(false);
      history.push("/");
    } catch (err) {
      setError("username", {
        type: "manual",
        message: err?.message,
      });
      setLoginin(false);
    }
  }; //sign in user

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-1 h-full">
      <div className="bg-kio-500 hidden h-full w-full rtl:order-2 flex-col items-center justify-center">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
      <div className="flex items-center justify-center rtl:text-right justify-self-center  md:w-3/5 ">
        <div
          className={`flex flex-col w-full lg:w-9/12 py-12 px-16 rtl:order-1 ${
            language === "ar" && "text-right"
          }`}
        >
          <Text size="xl2" weight="bold">
            {t("auth.identify")}
          </Text>
          <Text size="sm" color="text-gray-400">
            {t("auth.welcomeBack")}
          </Text>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputText
              placeholder={t("auth.username")}
              name="username"
              label={t("auth.username")}
              error={
                errors?.username
                  ? errors?.username?.message
                    ? errors?.username?.message
                    : t("auth.required")
                  : ""
              }
              register={register}
              required={true}
            />
            <InputText
              placeholder={t("auth.password")}
              name="password"
              label={t("auth.password")}
              error={errors?.password ? t("auth.required") : ""}
              type="password"
              register={register}
              required={true}
              underText={
                <Text color="text-black" size="sm" link>
                  <span
                    className="text-kio-500 text-sm cursor-pointer hover:text-black hover:underline"
                    onClick={() => history.push("/recover-account")}
                  >
                    {t("auth.forgetPassword")}
                  </span>
                </Text>
              }
              password={true}
            />
            <Space top="4">
              <Button disabled={loginIn} loading={loginIn}>
                {t("auth.connect")}
              </Button>
            </Space>

            <Space vertical="2">
              <Text size="sm" color="text-gray-400">
                {t("auth.new")}
                <span
                  className="hover:underline text-kio-500 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push("/signup");
                  }}
                >
                  {t("auth.register")}
                </span>
              </Text>
            </Space>
          </Form>
        </div>
      </div>
    </div>
  );
}
