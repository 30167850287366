import { Page, EmptyState } from "../../components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Notification } from "../../components/common/notification";
import { getInvitation } from "../../utils/services/invitation.service";
import Loading from "../common/Loading";

export function Notifications() {
  const [loading,setLoading] = useState(false);
  const [invitaions, setInvitations] = useState([]);
  const { t } = useTranslation();
  const [reload, setReload] = useState(false);
  useEffect(() => {
    setLoading(true)
    getInvitation().then(({ accepted, pending }) => {
      setInvitations(pending ?? []);
      setLoading(false)
    });
  }, [reload]);
  return (
    <>
      <Page loading={loading} title={t("invitation.notification.notification")}>
        {!loading ? (
          invitaions.length === 0 ? (
            <EmptyState />
          ) : (
            <>
              {invitaions.map((item) => {
                return (
                  <Notification
                    key={item.id}
                    item={item}
                    onAction={() => {
                      setReload(!reload);
                    }}
                  />
                );
              })}
            </>
          )
        ) : (
          <Loading />
        )}
      </Page>
    </>
  );
}
