import React from "react";

/**
 * Text
 * @description
 * @param {object} props Text props.
 * @param {boolean} props.link text is link.
 * @param {string} props.size font size (xs - sm - base - lg - xl - xl - xl2 - xl3 - xl-4 - xl-5).
 * @param {string} props.weight font weight (light - medium - bold).
 * @param {JSX.Element} props.children elements inside the components.
 * @param {string} props.color text color
 * @param {boolean} props.truncate text ellipsis
 * @param {boolean} props.italic text italic
 * @returns JSX.Element
 * @author
 */
export function Text({
  size = "lg",
  weight = "medium",
  color = "",
  link = false,
  uppercase = false,
  truncate = false,
  italic = false,
  className = "",
  children,
}) {
  return (
    <div
      className={`${className}  ${truncate ? "truncate" : ""} ${
        TEXT_SIZE[size]
      } ${FONT_WEIGHT[weight]} ${color} ${
        link ? "hover:underline cursor-pointer" : ""
      } ${uppercase ? "uppercase" : ""} ${italic && "italic"}`}
    >
      {children}
    </div>
  );
}

const TEXT_SIZE = {
  xs: "text-xs",
  sm: "text-sm",
  base: "text-base",
  lg: "text-lg",
  xl: "text-xl",
  xl2: "text-2xl",
  xl3: "text-3xl",
  xl4: "text-4xl",
  xl5: "text-5xl",
};

const FONT_WEIGHT = {
  light: "font-light",
  medium: "font-medium",
  bold: "font-bold",
};
