import axios from "./axios";

export const getSubscribedStores = (source, index = 1) => {
  return axios
    .get(`/subscribedstores?page=${index}`, {
      cancelToken: source.token,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    })
    .catch((error) => {
      if (error.message === "Network Error") return "network error";
      return error.response;
    });
};
