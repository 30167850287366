import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Text } from "../common/Text";
import { IconCard } from "../common/IconCard";
import { Button } from "../common/Button";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import { checkUserConfirmation, logout } from "../../utils/services";
import { useTranslation } from "react-i18next";

export function StepOne({ setConfirmationStep, setMethod, setHideArrowBack }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    checkUserConfirmation(user)
      .then((res) => {
        if (res) {
          setMethod(res === "email" ? 0 : 1);
          setHideArrowBack(true);
          setConfirmationStep(1);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [user, setMethod, setConfirmationStep, setHideArrowBack]);
  return (
    <>
      <Text size="sm" color="text-gray-400">
        {t("auth.confirmMethod")}
      </Text>
      <div className="flex flex-row justify-center py-16">
        {loading && (
          <div
            style={{ zIndex: 99999 }}
            className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black opacity-75 rounded-md"
          >
            <svg
              className="animate-spin h-5 w-5 mr-3 text-white"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-75"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-85"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        )}
        <IconCard
          icon={<MailIcon className="w-8" />}
          title={t("auth.recoverByEmail")}
          onClick={() => {
            setMethod(0);
            setConfirmationStep(1);
          }}
        />
        <IconCard
          icon={<PhoneIcon className="w-8" />}
          title={t("auth.recoverByPhone")}
          onClick={() => {
            setMethod(1);
            setConfirmationStep(1);
          }}
        />
      </div>
      <div className="flex flex-auto py-4 sm:py-0 justify-end">
        <Button
          fullWidth={false}
          responsiveFull={true}
          type="secondary"
          space
          onClick={() => logout(history, dispatch)}
        >
          {t("auth.deconnect")}
        </Button>
      </div>
    </>
  );
}
