import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  updateUserInformations,
  updateUserPassword,
} from "../../utils/services";
import { Button } from "../common/Button";
import { Form } from "../common/Form";
import { InputPhone } from "../common/InputPhone";
import { InputText } from "../common/InputText";
import { Item } from "../common/Item";
import { Modal } from "../common/Modal";
import { Text } from "../common/Text";
import { useTranslation } from "react-i18next";

export function MyAccout({ user, setUser, backIcon }) {
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm({ mode: "onChange" });
  const { t } = useTranslation();
  const history = useHistory();
  const MODAL_TITLES = {
    username: t("settings.myAccount.modifyUsername"),
    email: t("settings.myAccount.modifyEmail"),
    phone_number: t("settings.myAccount.modifyPhone"),
    password: t("settings.myAccount.modifyPassword"),
  };

  const [isOpen, setIsOpen] = useState(false);

  const [selectedField, setSelectedField] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [innerLoading, setInnerLoading] = useState(false);
  const dispatch = useDispatch();
  const onSubmit1 = () => {
    setIsOpen(true);
  };

  const onSubmit = async (data) => {
    let submitData = {};

    if (selectedField === "password") {
      try {
        setInnerLoading(true);
        await updateUserPassword(data, (x) => {
          return t(x);
        });
        setInnerLoading(false);
        setEditOpen(false);
        setIsOpen(false);
        reset();
      } catch (error) {
        setInnerLoading(false);

        setIsOpen(false);
        setError("old_password", {
          type: "manual",
          message: error?.message,
        });
      }
    } else {
      submitData[selectedField] = data[selectedField];
      try {
        setInnerLoading(true);
        let res = await updateUserInformations(dispatch, submitData, (x) => {
          return t(x);
        });
        setUser(res);
        setInnerLoading(false);
        setEditOpen(false);
        reset();
        setIsOpen(false);
        if (selectedField === "email" || selectedField === "phone_number") {
          history.push({
            pathname: "/confirm-account",
            state: {
              toConfirm: selectedField === "email" ? "email" : "phone",
            },
          });
        }
      } catch (error) {
        setError(selectedField, {
          type: "manual",
          message: error?.message,
        });
        setInnerLoading(false);
        setIsOpen(false);
      }
    }
  };

  return (
    <>
      <div className="mb-4 flex">
        <span className="rtl:transform  rtl:rotate-180 rtl:self-start">
          {backIcon}
        </span>
        <Text weight="bold">{t("settings.myAccount.modifyAccount")} </Text>
      </div>
      <Item
        label={t("auth.username")}
        text={user?.username}
        onClick={() => {
          setSelectedField("username");
          setEditOpen(true);
        }}
      />
      <Item
        label={t("auth.email")}
        text={
          user?.email !== null ? user?.email : user?.confirmation?.last_email
        }
        onClick={() => {
          setSelectedField("email");
          setEditOpen(true);
        }}
        verified={user?.email}
        pendingValue={
          !user?.email &&
          user?.confirmation?.email_confirmed === false &&
          user?.confirmation?.last_email
        }
      />
      <Item
        label={t("auth.phoneNumber")}
        text={
          user?.phone_number !== null
            ? user?.phone_number
            : user?.confirmation?.last_phone_number
        }
        onClick={() => {
          setSelectedField("phone_number");
          setEditOpen(true);
        }}
        verified={user?.phone_number}
        pendingValue={
          !user?.phone_number &&
          user?.confirmation?.phone_confirmed === false &&
          user?.confirmation?.last_phone_number
        }
      />
      <Item
        label={t("auth.password")}
        text={"********"}
        onClick={() => {
          setSelectedField("password");
          setEditOpen(true);
        }}
      />
      <Modal
        isOpen={editOpen}
        setIsOpen={setEditOpen}
        title={MODAL_TITLES[selectedField]}
        button={false}
        onClose={() => {
          reset();
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit1)}>
          {selectedField === "username" && (
            <InputText
              name="username"
              label={t("auth.username")}
              textClassName="rtl:text-right"
              register={register}
              defaultValue={user?.username}
              required
              pattern={{
                value: /^[A-Za-z\u0600-\u06FF][A-Za-z0-9_\u0600-\u06FF]{4,20}$/,
                message: t("errorMessage.errorUsername"),
              }}
              error={
                errors?.username
                  ? ["manual", "pattern"].includes(errors?.username?.type)
                    ? errors?.username?.message
                    : t("auth.required")
                  : ""
              }
            />
          )}
          {selectedField === "email" && (
            <InputText
              label={t("auth.email")}
              type="email"
              name="email"
              textClassName="rtl:text-right"
              register={register}
              required
              pattern={{
                value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                message: t("errorMessage.invalidEmail"),
              }}
              defaultValue={user?.email}
              error={
                errors?.email
                  ? ["manual", "pattern"].includes(errors?.email?.type)
                    ? errors?.email?.message
                    : t("auth.required")
                  : ""
              }
            />
          )}
          {selectedField === "phone_number" && (
            <InputPhone
              label={t("auth.phoneNumber")}
              textClassName="rtl:text-right"
              control={control}
              required
              register={register}
              defaultValue={user?.phone_number}
              error={
                errors?.phone_number
                  ? ["manual"].includes(errors?.phone_number?.type)
                    ? errors?.phone_number?.message
                    : t("auth.required")
                  : ""
              }
            />
          )}
          {selectedField === "password" && (
            <>
              <InputText
                label={t("auth.password")}
                type="password"
                textClassName="rtl:text-right"
                name="old_password"
                required
                register={register}
                minLength={8}
                error={
                  errors?.old_password
                    ? ["manual", "pattern", "validate"].includes(
                        errors?.old_password?.type
                      )
                      ? errors?.old_password.message
                      : errors?.old_password?.type === "minLength"
                      ? t("errorMessage.errorPassword")
                      : t("auth.required")
                    : ""
                }
              />
              <InputText
                label={t("auth.nvPassword")}
                type="password"
                textClassName="rtl:text-right"
                name="new_password"
                required
                register={register}
                minLength={8}
                pattern={{
                  value:
                    /^(?=.*[A-Z])(?=.*[()[\]{}|\\~!@#$%^&*_\-+=;:,<>./?])(?=.*\d).*$/,
                  message: t("auth.strongPassword"),
                }}
                error={
                  errors?.new_password
                    ? ["manual", "pattern"].includes(errors?.new_password?.type)
                      ? errors?.new_password?.message
                      : errors?.new_password?.type === "minLength"
                      ? t("errorMessage.errorPassword")
                      : t("auth.required")
                    : ""
                }
              />
              <InputText
                label={t("auth.confirmPassword")}
                type="password"
                textClassName="rtl:text-right"
                name="confirm_password"
                required
                register={register}
                validate={(value) =>
                  value === getValues("new_password") ||
                  t("errorMessage.differentPassword")
                }
                error={
                  errors?.confirm_password
                    ? ["manual", "validate"].includes(
                        errors?.confirm_password?.type
                      )
                      ? errors?.confirm_password?.message
                      : t("auth.required")
                    : ""
                }
              />
            </>
          )}
          <div className="mt-4">
            <div className="w-full flex justify-end">
              <Button
                size="small"
                fullWidth={false}
                loading={innerLoading}
                disabled={Object.keys(errors).length !== 0}
              >
                {t("commons.modify")}
              </Button>
            </div>
          </div>
        </Form>
        <Modal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={MODAL_TITLES[selectedField]}
          button={false}
          onClose={() => {
            reset();
          }}
        >
          <div>{t("settings.myAccount.sureModify")} </div>
          <div className="mt-4">
            <div className="w-2/6 ml-auto flex justify-between">
              <Button
                onClick={() => setIsOpen(false)}
                type="secondary"
                size="small"
                fullWidth={false}
              >
                {t("settings.no")}
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                type="delete"
                size="small"
                fullWidth={false}
                loading={innerLoading}
              >
                {t("settings.yes")}
              </Button>
            </div>
          </div>
        </Modal>
      </Modal>
    </>
  );
}
