import React, { useRef, useEffect } from "react";

/**
 * CodeInput
 * @description
 * @param {object} props Text props.
 * @param {Function} props.setCode change code state.
 * @param {Array} props.code code array of numbers .
 * @param {Function} props.onChange handle on change input text.
 * @returns JSX.Element
 * @author
 */
export function CodeInput({ setCode, code, onChange = () => {} }) {
  const list = [...Array(6).keys()];
  const inputRef = useRef([]);
  useEffect(() => {
    if (setCode) setCode(Array.from(new Array(6), () => ""));
  }, [setCode]);

  return (
    <div className="flex flex-row rtl:flex-row-reverse justify-center py-16 md:py-12">
      {list.map((key) => (
        <input
          ref={(el) => (inputRef.current[key] = el)}
          key={key}
          type="number"
          onChange={(e) => {
            onChange();
            let value = e.target.value;
            if (+value <= 9 && +value >= 0) {
              code[key] = value;
              setCode([...code]);
            }
            if (value.length > 0 && key + 1 !== 6)
              inputRef.current[key + 1].focus();
          }}
          value={code[key]}
          className="hide-input-arrow w-10 h-10 sm:w-12 sm:h-12 bg-gray-100 mx-1 sm:mx-2 rounded-md shadow-inner ring-2 ring-gray-200	focus:outline-none focus:ring-2 focus:ring-indigo-200 text-center text-lg	font-bold"
        />
      ))}
    </div>
  );
}
