import React, { useEffect, useState } from "react";
import { Text, StepOne, StepTwo, StepThree } from "../../components/";
import { ArrowSmLeftIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import kio from "../../assets/images/kio-primary.svg";

export default function ConfirmAccount() {
  const [confirmationStep, setConfirmationStep] = useState(1);
  const [method, setMethod] = useState(null); //confirmation method 0: mail 1:phone
  const [showTimer, setShowTimer] = useState(false);
  const [, setHideArrowBack] = useState(false);
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  let history = useHistory();
  let location = useLocation();
  let fieldToconfirm = location?.state?.toConfirm;

  useEffect(() => {
    if (user) {
      if (!fieldToconfirm) {
        history.push("/settings");
      }
      if (
        user?.confirmation?.email_confirmed === false &&
        fieldToconfirm === "email"
      ) {
        setMethod(0);
        setConfirmationStep(3);
      } else if (
        user?.confirmation?.phone_confirmed === false &&
        fieldToconfirm === "phone"
      ) {
        setMethod(1);
        setConfirmationStep(3);
      } else if (
        user.confirmation?.last_email !== null &&
        user?.confirmation?.email_confirmed === false
      )
        setMethod(0);
      else if (
        user.confirmation?.last_phone_number !== null &&
        user?.confirmation?.phone_confirmed === false
      )
        setMethod(1);
    }
  }, []); // eslint-disable-line
  return (
    <div className="flex flex-col justify-center items-center gap-6 h-full sm:px-6">
      <div className="cursor-pointer" onClick={() => history.push("/")}>
        <img src={kio} alt="kio's logo" className="h-24 w-24 sm:h-10 " />
      </div>
      <div className="w-full md:w-9/12 lg:w-6/12 p-6 md:rounded-md shadow ring-2 ring-gray-200">
        <div className="flex flex-row justify-between items-baseline">
          <Text size="xl" weight="bold">
            {t("auth.confirmAccount")}
          </Text>
          {confirmationStep === 2 && !fieldToconfirm && (
            <ArrowSmLeftIcon
              className="w-6 cursor-pointer hover:opacity-50"
              onClick={() => {
                if (confirmationStep === 2) setConfirmationStep(1);
                else setConfirmationStep(0);
              }}
            />
          )}
          {fieldToconfirm && (
            <div onClick={() => history.push("/")}>
              <Text
                size="sm"
                className="text-kio-500 font-bold cursor-pointer hover:text-indigo-700"
              >
                {t("auth.backHome")}
              </Text>
            </div>
          )}
        </div>
        {confirmationStep === 0 ? (
          <StepOne
            setMethod={setMethod}
            setConfirmationStep={setConfirmationStep}
            setHideArrowBack={setHideArrowBack}
          />
        ) : confirmationStep === 1 ? (
          <StepTwo
            toConfirm={location?.state?.toConfirm}
            method={method}
            setShowTimer={setShowTimer}
            showTimer={showTimer}
            setConfirmationStep={setConfirmationStep}
          />
        ) : (
          <StepThree
            toConfirm={location?.state?.toConfirm}
            method={method}
            setShowTimer={setShowTimer}
            showTimer={showTimer}
            setConfirmationStep={setConfirmationStep}
          />
        )}
      </div>
    </div>
  );
}
