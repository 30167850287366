import { ArrowRightIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
export const ListItem = (
  { id, src, description, title, secondary, caption, url },
  index
) => {
  return (
    <Link to={{ pathname: url }} target="_blank">
      <li
        key={id}
        className={`border-gray-400 flex flex-row ${
          index === 0 ? "mb-2" : "my-2"
        }`}
      >
        <div className="px-2 sm:px-4 py-4 bg-white rounded-md outline-none ring-2 ring-gray-200 select-none cursor-pointer  flex flex-1 items-center transition duration-300 shadow ease-in-out transform hover:-translate-y-1 hover:shadow-md">
          <div className="flex flex-col w-60 h-32 justify-center items-center mr-4">
            <img
              alt={title}
              src={src}
              className="mx-auto object-cover rounded-md h-32 w-full "
            />
          </div>
          <div className="flex-1 pl-1 ltr:md:mr-16  rtl:mr-4 ">
            <div className="font-medium dark:text-white">{title}</div>
            <div className="text-gray-600 dark:text-gray-200 text-sm">{`${description}`}</div>
            <div className="text-gray-600 dark:text-gray-200 text-sm">{`${caption}`}</div>
          </div>
          <div className="text-gray-800 rtl:ltr-text text-xs">{`${secondary}`}</div>
          <ArrowRightIcon className=" hidden md:block h-6 w-6 ml-4 rtl:transform rtl:rotate-180" />
        </div>
      </li>
    </Link>
  );
};

export const List = ({ items = [], loading = false }) => {
  return (
    <div className="container flex flex-col mx-auto w-full items-center justify-center">
      <ul className="flex flex-col w-full relative">
        {loading && (
          <div className="absolute top-0 left-0 h-full w-full bg-black opacity-75 z-50">
            <div className="w-fulll h-full flex items-center justify-center">
              <svg
                className="animate-spin h-10 w-10 text-white"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-75"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="2"
                ></circle>
                <path
                  className="opacity-85"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          </div>
        )}
        {items.map((item) => (
          <ListItem key={item.id} {...item} />
        ))}
      </ul>
    </div>
  );
};
