import axios from "./axios";
export const getAllWishes = (page, source) => {
  return axios
    .get(`/wishlist/?page=${page}`, {
      cancelToken: source.token,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.message === "Network Error") return "network error";
      return error.response;
    });
};
