import { Item } from "../common/Item";
import { Modal } from "../common/Modal";
import { Text } from "../common/Text";
import { useState, useEffect } from "react";
import { Button } from "../common/Button";
import {
  getAddresses,
  generateAddresse,
  deleteAddresse,
  getCountriesStatesCities,
  onCountryChange,
  onStateChange,
  addAddress,
  editAddress,
} from "../../utils/services/addresses.service";
import axios from "axios";
import Loading from "../../pages/common/Loading";
import EmptyState from "../common/EmptyState";
import { Form } from "../common/Form";
import { InputText } from "../common/InputText";
import { Select } from "../common/Select";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { formatSelectData } from "../utils";
import { PencilIcon, TrashIcon, EyeIcon } from "@heroicons/react/outline";

export function Addresses({ backIcon }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({ mode: "onChange" }); //form validation
  const [isOpen, setIsOpen] = useState(false);
  const [isAddAdressOpen, setIsAddAdressOpen] = useState(false);
  const [isEditAdressOpen, setIsEditAdressOpen] = useState(false);
  const [showAdressOpen, setShowAdressOpen] = useState(false);

  const [disabledButton, setDisabledButton] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddresse, setSelectedAddresse] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [innerLoading, setInnerLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    const source = axios.CancelToken.source();
    setLoading(true);
    setDisabledButton(true);
    getAddresses(source)
      .then((res) => {
        if (res?.status === 404) setAddresses([]);
        else setAddresses(res);
        setLoading(false);
      })
      .catch((err) => {});
    getCountriesStatesCities(source)
      .then(({ countries, states, cities }) => {
        setCountries(countries);
        setStates(states);
        setCities(cities);
        setSelectedCountry(countries[0]);
        setSelectedState(states[0]);
        setSelectedCity(cities[0]);
        setDisabledButton(false);
      })
      .catch((err) => {});
    return () => {
      source.cancel();
    };
  }, [reload]);

  const onSubmit = async (data) => {
    data = {
      ...data,

      city: selectedCity?.value,
      state: selectedState?.value,
      country: selectedCountry?.value,
    };
    // ADD ID TO DATA OBJECT IN UPDATE SINGLE ADDRESS
    if (isEditAdressOpen) {
      if (
        isDataUpdated(
          selectedAddresse,
          getValues(),
          selectedCountry,
          selectedState,
          selectedCity
        )
      ) {
        return;
      }
      data.id = selectedAddresse?.id;
    }

    try {
      setInnerLoading(true);
      if (isAddAdressOpen) {
        await addAddress(data);
      } else {
        await editAddress(data);
      }

      setInnerLoading(false);
      setIsAddAdressOpen(false);
      setIsEditAdressOpen(false);
      setSelectedAddresse([]);
      reset();
      setReload(!reload);
    } catch (err) {
      setInnerLoading(false);
    }
  };
  return (
    <>
      <div className="mb-4 flex justify-between">
        <span className="rtl:transform  rtl:rotate-180 rtl:self-start">
          {backIcon}
        </span>
        <Text weight="bold"> {t("settings.myAddresses.myAddresses")} </Text>
        <Button
          fullWidth={false}
          type="update"
          size="small"
          disabled={disabledButton}
          onClick={() => {
            setIsAddAdressOpen(true);
            setSelectedCountry(countries[0]);
            onCountryChange(
              countries[0]?.value,
              axios.CancelToken.source()
            ).then(({ states, cities }) => {
              setStates(states);
              setCities(cities);
              setSelectedState(states[0]);
              setSelectedCity(cities[0]);
            });
          }}
        >
          {t("settings.myAddresses.addAddress")}
        </Button>
      </div>
      {!loading ? (
        addresses?.map((addresse, index) => (
          <div className="flex justify-between">
            <Item
              key={addresse.name + index}
              update={false}
              label={
                addresse?.city?.name +
                ", " +
                addresse?.state?.name +
                ", " +
                addresse?.country?.name
              }
              text={generateAddresse(addresse)}
              onClick={null}
            />
            <div>
              <Button
                type={"primary_text"}
                fullWidth={false}
                size="small"
                PaddingX="px-2"
                onClick={() => {
                  setSelectedAddresse(addresse);
                  setValue("name", addresse.name);
                  setValue("street", addresse.street);
                  setValue("street2", addresse.street2);
                  setShowAdressOpen(true);
                }}
              >
                <EyeIcon className="w-4 h-4"></EyeIcon>
              </Button>

              <Button
                type={"update_text"}
                fullWidth={false}
                size="small"
                PaddingX="px-2"
                onClick={() => {
                  setSelectedAddresse(addresse);
                  setValue("name", addresse.name);
                  setValue("street", addresse.street);
                  setValue("street2", addresse.street2);
                  let localCountry = formatSelectData(addresse.country);
                  let localSatate = formatSelectData(addresse.state);
                  setSelectedCountry(localCountry);
                  setSelectedState(localSatate);
                  setSelectedCity(formatSelectData(addresse.city));
                  onCountryChange(
                    addresse.country.id,
                    axios.CancelToken.source()
                  ).then(({ states, cities }) => {
                    setStates(states);
                  });
                  onStateChange(
                    localCountry.value,
                    localSatate.value,
                    axios.CancelToken.source()
                  ).then(({ cities }) => {
                    setCities(cities);
                  });

                  setIsEditAdressOpen(true);
                }}
              >
                <PencilIcon className="w-4 h-4"></PencilIcon>
              </Button>
              <Button
                type={"delete_text"}
                fullWidth={false}
                size="small"
                PaddingX="px-2"
                onClick={() => {
                  setSelectedAddresse(addresse);
                  setIsOpen(true);
                }}
              >
                <TrashIcon className="w-4 h-4"></TrashIcon>
              </Button>
            </div>
          </div>
        ))
      ) : (
        <Loading bg="" fullScreen={false} loading={loading} />
      )}
      {!loading && addresses.length === 0 && (
        <>
          <EmptyState text={t("settings.myAddresses.noAddress")} />
        </>
      )}
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={t("settings.myAddresses.deleteAdress?")}
        button={false}
      >
        <div>
          {t("settings.myAddresses.deleteadressMsg")} "
          {generateAddresse(selectedAddresse)}" ?
        </div>
        <div className="mt-4">
          <div className="w-2/6 ml-auto flex justify-between">
            <Button
              onClick={() => setIsOpen(false)}
              type="secondary"
              size="small"
              fullWidth={false}
            >
              {t("settings.no")}
            </Button>
            <Button
              onClick={() => {
                const source = axios.CancelToken.source();
                setLoading(true);
                setIsOpen(false);

                deleteAddresse(selectedAddresse?.id).then((res) => {
                  getAddresses(source).then((res) => {
                    if (res?.status === 404) setAddresses([]);
                    else setAddresses(res);
                    setLoading(false);
                  });
                });
              }}
              type="delete"
              size="small"
              fullWidth={false}
            >
              {t("settings.yes")}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isAddAdressOpen}
        setIsOpen={setIsAddAdressOpen}
        title={t("settings.myAddresses.addNewAddress")}
        onClose={() => {
          reset();
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputText
            label={t("settings.myAddresses.addressTitle")}
            name="name"
            textClassName="rtl:text-right"
            register={register}
          />
          <InputText
            label={t("settings.myAddresses.address1")}
            name="street"
            register={register}
            textClassName="rtl:text-right"
            required
            error={errors?.street ? t("auth.required") : ""}
          />
          <InputText
            label={t("settings.myAddresses.address2")}
            name="street2"
            textClassName="rtl:text-right"
            register={register}
          />
          <Select
            label={t("settings.myAddresses.country")}
            textClassName="rtl:text-right"
            options={countries}
            selected={selectedCountry}
            setSelected={setSelectedCountry}
            onOptionSelected={(value) => {
              const source = axios.CancelToken.source();
              onCountryChange(value, source).then(({ states, cities }) => {
                setStates(states);
                setCities(cities);
                setSelectedState(states[0]);
                setSelectedCity(cities[0]);
              });
            }}
          />
          <div className="flex flex-row rtl:gap-1">
            <div className="flex-1 mr-2 my-2">
              <Select
                label={t("settings.myAddresses.wilaya")}
                textClassName="rtl:text-right"
                options={states}
                selected={selectedState}
                setSelected={setSelectedState}
                onOptionSelected={(value) => {
                  const source = axios.CancelToken.source();
                  onStateChange(selectedCountry?.value, value, source).then(
                    ({ cities }) => {
                      setCities(cities);
                      setSelectedCity(cities[0]);
                    }
                  );
                }}
              />
            </div>
            <div className="flex-1 ml-2  my-2">
              <Select
                label={t("settings.myAddresses.city")}
                textClassName="rtl:text-right"
                selected={selectedCity}
                setSelected={setSelectedCity}
                options={cities}
              />
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            <Button
              type="primary"
              size="small"
              fullWidth={false}
              loading={innerLoading}
              disabled={Object.keys(errors).length !== 0}
            >
              {t("commons.save")}
            </Button>
          </div>
        </Form>
      </Modal>

      {/* EDIT MODAL */}
      <Modal
        isOpen={isEditAdressOpen || showAdressOpen}
        setIsOpen={isEditAdressOpen ? setIsEditAdressOpen : setShowAdressOpen}
        title={
          isEditAdressOpen
            ? t("settings.myAddresses.editAddress")
            : t("settings.myAddresses.showAddress")
        }
        onClose={() => {
          setSelectedAddresse([]);

          reset();
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputText
            label={t("settings.myAddresses.addressTitle")}
            name="name"
            textClassName="rtl:text-right"
            register={register}
            disabled={showAdressOpen}
          />
          <InputText
            label={t("settings.myAddresses.address1")}
            name="street"
            register={register}
            textClassName="rtl:text-right"
            required
            error={errors?.street ? t("auth.required") : ""}
            disabled={showAdressOpen}
          />
          <InputText
            label={t("settings.myAddresses.address2")}
            name="street2"
            textClassName="rtl:text-right"
            register={register}
            disabled={showAdressOpen}
          />

          {isEditAdressOpen ? (
            <Select
              label={t("settings.myAddresses.country")}
              textClassName="rtl:text-right"
              options={countries}
              selected={selectedCountry}
              setSelected={setSelectedCountry}
              onOptionSelected={(value) => {
                const source = axios.CancelToken.source();
                onCountryChange(value, source).then(({ states, cities }) => {
                  setStates(states);
                  setCities(cities);
                  setSelectedState(states[0]);
                  setSelectedCity(cities[0]);
                });
              }}
            />
          ) : (
            <InputText
              label={t("settings.myAddresses.country")}
              name="street2"
              textClassName="rtl:text-right"
              defaultValue={selectedAddresse?.country?.name}
              disabled={showAdressOpen}
            />
          )}

          <div className="flex flex-row rtl:gap-1">
            <div className="flex-1 mr-2 my-2">
              {isEditAdressOpen ? (
                <Select
                  label={t("settings.myAddresses.wilaya")}
                  textClassName="rtl:text-right"
                  options={states}
                  selected={selectedState}
                  setSelected={setSelectedState}
                  onOptionSelected={(value) => {
                    const source = axios.CancelToken.source();
                    onStateChange(selectedCountry?.value, value, source).then(
                      ({ cities }) => {
                        setCities(cities);
                        setSelectedCity(cities[0]);
                      }
                    );
                  }}
                />
              ) : (
                <InputText
                  label={t("settings.myAddresses.wilaya")}
                  name="street2"
                  textClassName="rtl:text-right"
                  defaultValue={selectedAddresse?.state?.name}
                  disabled={showAdressOpen}
                />
              )}
            </div>
            <div className="flex-1 ml-2  my-2">
              {isEditAdressOpen ? (
                <Select
                  label={t("settings.myAddresses.city")}
                  textClassName="rtl:text-right"
                  selected={selectedCity}
                  setSelected={setSelectedCity}
                  options={cities}
                />
              ) : (
                <InputText
                  label={t("settings.myAddresses.city")}
                  name="street2"
                  textClassName="rtl:text-right"
                  defaultValue={selectedAddresse?.city?.name}
                  disabled={showAdressOpen}
                />
              )}
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            {isEditAdressOpen && (
              <Button
                type="primary"
                size="small"
                fullWidth={false}
                loading={innerLoading}
                disabled={Object.keys(errors).length !== 0}
              >
                {t("commons.save")}
              </Button>
            )}
          </div>
        </Form>
      </Modal>
    </>
  );
}

function isDataUpdated(address, newValues, country, state, city) {
  return (
    address.name === newValues.name &&
    address.street === newValues.street &&
    address.street2 === newValues.street2 &&
    address.country.id === country.value &&
    address.state.id === state.value &&
    address.city.id === city.value
  );
}
