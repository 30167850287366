import React from "react";
import { Button } from "./Button";
import { Text } from "./Text";
import { useTranslation } from "react-i18next";
import { ReactComponent as Checked } from "../../assets/images/checked.svg";
import { ReactComponent as Unchecked } from "../../assets/images/unchecked.svg";

export function Item({
  label,
  text,
  onClick = () => {},
  update = true,
  verified,
  pendingValue,
}) {
  const { t } = useTranslation();

  return (
    <div
      className={`flex justify-between ${onClick !== null ? "mb-8" : "mb-4"}`}
    >
      <div
        className="flex flex-col w-3/5  sm:w-max  max-w-3xl"
        style={{ minWidth: "200px", maxWidth: "200px" }}
      >
        <Text color="text-gray-300" size="xs" weight="bold" uppercase>
          {label}
        </Text>
        <Text
          size="sm"
          weight="bold"
          truncate
          className={" text-gray-400  rtl:ltr-text rtl:text-right"}
        >
          {text}
        </Text>
      </div>
      <div className=" flex items-center">
        {verified && (
          <>
            <Checked />

            <Text size="sm" weight="bold" color="text-kio-500">
              {t("auth.verified")}
            </Text>
          </>
        )}
        {pendingValue && (
          <>
            <Unchecked />

            <Text size="sm" weight="bold" color="text-red-500">
              {t("auth.notVerified")}
            </Text>
          </>
        )}
      </div>

      {onClick !== null && (
        <div>
          <Button
            type={update ? "update" : "delete"}
            fullWidth={false}
            size="small"
            onClick={onClick}
          >
            {update ? t("commons.modify") : t("commons.delete")}
          </Button>
        </div>
      )}
    </div>
  );
}
