import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CheckCircleIcon, LibraryIcon } from "@heroicons/react/outline";
import { Page, Text } from "../../components";
import { findStoreBySlug } from "../../utils/services";
import { useTranslation } from "react-i18next";
export default function StoreDetails({ slug }) {
  const { stores } = useSelector((state) => state.stores);
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    const found = findStoreBySlug(stores, slug);
    setLoading(false);
    setCurrent(found);
  }, [slug, stores]);

  return (
    <>
      <Page
        title={
          <div className="flex-1 flex flex-col items-start justify-center">
            <span>{current?.name ?? slug}</span>
            <span className="text-sm text-gray-500">
              {current?.points ?? 0} {t("stores.koinz")}
            </span>
          </div>
        }
        loading={loading}
        breadcrumbs={true}
      >
        <hr />
        {/* details */}
        <div className="flex flex-col items-center text-center">
          {/* logo and follow / edit */}
          <div className="h-32 w-32 my-4 relative flex flex-col items-center">
            <div className="h-32 w-32 rounded-full flex items-center justify-center">
              {current?.logo_src ? (
                <img
                  src={current?.logo_src}
                  alt={current?.name ?? slug}
                  className="h-100 w-100 rounded-full flex items-center justify-center"
                />
              ) : (
                <div className="h-32 w-32 rounded-full flex items-center justify-center bg-gray-100">
                  <LibraryIcon className="h-8 w-8 text-gray-600" />
                </div>
              )}
            </div>
            <CheckCircleIcon className="absolute -bottom-3 h-10 w-10 cursor-pointer text-kio-500 bg-gray-100 white rounded-full shadow" />
          </div>

          {/* text stuff */}
          <Text size="xl4" uppercase={true}>
            {current?.name ?? slug}
          </Text>
          <span className="my-2">
            <Text size="base" color="text-gray-500">
              @{slug}
            </Text>
          </span>
          <span className="my-2">
            <Text size="lg" color="text-gray-700">
              {current?.description ?? t("stores.noDescription")}
            </Text>
          </span>

          <a
            target="_blank"
            rel="noreferrer"
            href={current?.store_url}
            className="mt-2"
          >
            <Text size="base" color="text-kio-500" link={true}>
              {current?.store_url}
            </Text>
          </a>
          <span className="">
            <Text size="base" color="text-gray-700">
              {t("stores.joined")}
              {new Intl.DateTimeFormat("fr-FR", {
                year: "numeric",
                month: "long",
                day: "2-digit",
              }).format(Date.parse(current?.created ?? new Date()))}
            </Text>
          </span>
        </div>
        {/* details */}
      </Page>
    </>
  );
}
