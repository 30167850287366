import { useScreenType } from "../../hooks";
import {
  LeftPanel,
  RightPanel,
  TabletSidebar,
  Header,
} from "../../components/";

import {
  HomeIcon,
  // LocationMarkerIcon,
  //InboxIcon,
  ShoppingCartIcon,
  UserIcon,
  AdjustmentsIcon,
  LogoutIcon,
  LibraryIcon,
  HeartIcon,
  ArrowSmLeftIcon,
} from "@heroicons/react/outline";

import { logout } from "../../utils/services/";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MemoizedWrapper } from "./MainScreen";
import { useTranslation } from "react-i18next";

export const MainLayout = ({ children }) => {
  const screenType = useScreenType();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { language } = useSelector((state) => state.language);

  // check if connection comming from storeFront to hide logout button
  const fromStore = window.localStorage.getItem("fromStore");

  const { t } = useTranslation();
  const elements = [
    {
      key: "primary",
      primary: true,
      children: [
        {
          key: "/",
          title: `${t("home.home")}`,
          icon: <HomeIcon className="h-6 w-6 " />,
        },
        // {
        //   key: "/explore",
        //   title: `${t("explore.discover")}`,
        //   icon: <LocationMarkerIcon className="h-6 w-6 " />,
        // },
        /*{
          key: "/inbox",
          title: "Messages",
          icon: <InboxIcon className="h-6 w-6 " />,
        },*/
        {
          key: "/orders/all",
          title: `${t("orders.orders")}`,
          icon: <ShoppingCartIcon className="h-6 w-6 " />,
        },
        {
          key: "/wish-lists/all",
          title: `${t("wishList.wishList")}`,
          icon: <HeartIcon className="h-6 w-6 " />,
        },
        {
          key: "/stores/all",
          title: `${t("stores.myStores")}`,
          icon: <LibraryIcon className="h-6 w-6" />,
        },
        {
          key: `/user/${user?.username}`,
          title: `${t("profil.myProfil")}`,
          icon: <UserIcon className="h-6 w-6 " />,
        },
      ],
    },
    {
      key: "secondary",
      primary: false,
      children: [
        {
          key: "/settings",
          title: `${t("settings.settings")}`,
          icon: <AdjustmentsIcon className="h-6 w-6 " />,
        },
        fromStore
          ? {
              key: "/",
              title: `${t("stores.backStore")}`,
              icon: <ArrowSmLeftIcon className="h-6 w-6 " />,
              onClick: () => {
                //TODO

                window.location = window.location.origin;
              },
            }
          : {
              key: "/logout",
              title: `${t("auth.deconnect")}`,
              icon: <LogoutIcon className="h-6 w-6 " />,
              onClick: () => {
                //TODO
                logout(history, dispatch);
              },
            },
      ],
    },
  ];
  let middle = null;
  let top = null;

  switch (screenType) {
    case "3-cols":
      middle = (
        <>
          <LeftPanel elements={elements} />
          <MemoizedWrapper>{children}</MemoizedWrapper>
          <RightPanel />
        </>
      );
      break;
    case "2-cols":
      middle = (
        <>
          <TabletSidebar elements={elements} />
          <MemoizedWrapper>{children}</MemoizedWrapper>
          <RightPanel />
        </>
      );
      break;
    case "1-cols":
      middle = (
        <>
          <TabletSidebar elements={elements} />
          <MemoizedWrapper>{children}</MemoizedWrapper>
        </>
      );
      break;
    case "fullscreen":
      top = <Header elements={elements} />;
      middle = (
        <>
          <div className="hidden" />
          <MemoizedWrapper>{children}</MemoizedWrapper>
        </>
      );

      break;
    default:
      middle = <></>;
  }

  if (language === "ar") {
    document.documentElement.setAttribute("dir", "rtl");
  } else {
    document.documentElement.setAttribute("dir", "ltr");
  }

  return (
    <>
      {location.pathname === "/confirm-account" ? (
        <div></div>
      ) : (
        <div className="w-screen h-screen flex flex-col">
          {top}
          <div className="flex-1 w-screen overflow-x-hidden overflow-y-hidden ">
            <div className="h-full w-full flex bg-gray-50">{middle}</div>
          </div>
        </div>
      )}
    </>
  );
};
