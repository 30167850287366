import { useEffect, useState } from "react";

export const Loading = ({ loading, ...props }) => {
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    setPercentage(0);
  }, [loading]);
  useEffect(() => {
    let timeOutId;
    let progress = () => {
      const aux = percentage + 1;
      setPercentage(aux);
    };
    if (percentage < 25) {
      timeOutId = setTimeout(progress, 500);
    } else if (percentage < 50) {
      timeOutId = setTimeout(progress, 1000);
    } else if (percentage < 75) {
      timeOutId = setTimeout(progress, 1500);
    } else if (percentage < 100) {
      timeOutId = setTimeout(progress, 5000);
    }

    return () => {
      clearTimeout(timeOutId);
    };
  }, [percentage]);
  return (
    <div className="absolute top-0 left-0 w-full">
      <div className="w-full h-2">
        <div
          className={`h-full bg-kio-500 rounded-r-full transition-all duration-150 ease-in-out ${
            !loading && "hidden"
          }`}
          style={{
            width: `${percentage}%`,
          }}
        ></div>
      </div>
    </div>
  );
};
