import {
  setAuthToken,
  getToken,
  signUp,
  phoneVerification,
  getResetPasswordCode,
  confrimResetPasswordCode,
  resetPassword,
  getResetPasswordToken,
  resetPasswordEmail,
  checkRecaptchaToken,
} from "../../api/";
import Cookies from "js-cookie";
import { saveUser } from "../../redux/actions";

const setCookies = (token) => {
  var in10Hours = new Date(new Date().getTime() + 10 * 60 * 60 * 1000);
  Cookies.set("token", token, { expires: in10Hours });
};

export const signin = async (data, t = () => {}) => {
  const mydata = {
    grant_type: "password",
    client_id: process.env.REACT_APP_CLIENT_ID,
    username: data.username,
    password: data.password,
  };

  try {
    const res = await getToken(mydata);

    if (res && res !== "network error") {
      if (res.status === 200) {
        localStorage.setItem("refresh_token", res.data.refresh_token);
        const token = res.data.access_token;
        setAuthToken(token);
        setCookies(token);
        return token;
      } else if (res.status === 400) {
        throw Error(t("errorMessage.userNameNotFound"));
      }
    } else if (res === "network error") {
      throw Error(t("errorMessage.noInternetConnection"));
    }
  } catch (err) {
    throw err;
  }
};

export const signup = async (data, dispatch, t = () => {}) => {
  try {
    const res = await signUp(data);

    if (res?.status === 400) {
      if (
        res?.data?.email &&
        res?.data?.email[0] === "user with this email address already exists."
      )
        throw Error(
          JSON.stringify({
            field: "email",
            msg: t("errorMessage.emailAlreadyExists"),
          })
        );
      else if (res?.data?.username) {
        if (
          res?.data?.username[0] === "user with this username already exists."
        )
          throw Error(
            JSON.stringify({
              field: "username",
              msg: t("errorMessage.accountAlreadyExists"),
            })
          );
        else if (
          res?.data?.username[0] ===
          "Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters."
        )
          throw Error(
            JSON.stringify({
              field: "username",
              msg: t("errorMessage.invalidValue"),
            })
          );
      } else if (res?.data?.phone_number) {
        if (
          res?.data?.phone_number[0] ===
          "user with this phone number already exists."
        )
          throw Error(
            JSON.stringify({
              field: "phone_number",
              msg: t("errorMessage.phoneAlreadyExists"),
            })
          );
        else if (
          res?.data?.phone_number[0] ===
          "Phone number must be entered in the format: '+999999999999'. Up to 15 digits allowed."
        ) {
          throw Error(
            JSON.stringify({
              field: "phone_number",
              msg: t("errorMessage.invalidValue"),
            })
          );
        }
      } else if (res.data["Details "]?.includes("Phone number incorrect.")) {
        throw Error(
          JSON.stringify({
            field: "phone_number",
            msg: t("errorMessage.incorrectPhone"),
          })
        );
      } else if (res?.data?.last_name) {
        throw Error(
          JSON.stringify({
            field: "last_name",
            msg: t("errorMessage.maxLenght"),
          })
        );
      } else if (res?.data?.first_name) {
        throw Error(
          JSON.stringify({
            field: "first_name",
            msg: t("errorMessage.maxLenght"),
          })
        );
      }
    } else if (res?.status === 201) {
      const mydata = {
        grant_type: "password",
        client_id: process.env.REACT_APP_CLIENT_ID,
        username: res?.data?.username,
        password: data?.password,
      };
      const tokenResponse = await getToken(mydata);
      localStorage.setItem("refresh_token", tokenResponse.data.refresh_token);
      setAuthToken(tokenResponse.data.access_token);
      setCookies(tokenResponse.data.access_token);
      dispatch(saveUser(res?.data));
      return;
    } else if (res === "network error")
      throw Error(
        JSON.stringify({
          field: "connection",
          msg: t("errorMessage.verifyConnection"),
        })
      );
  } catch (err) {
    throw err;
  }
};

export const confirmPhone = async (data, user, dispatch, t = () => {}) => {
  try {
    const res = await phoneVerification(data);
    if (res?.status === 406) {
      if (res?.data?.Details === "Code incorrect")
        throw Error(t("errorMessage.codeIncorrect"));
    } else if (res?.status === 429) {
      throw Error(t("errorMessage.tryLater"));
    } else if (res?.status === 200 || res?.status === 201) {
      user.isconfirmed = true;
      dispatch(saveUser(user));
      return;
    } else if (res === "network error")
      throw Error(t("errorMessage.verifyConnection"));
  } catch (err) {
    throw err;
  }
};

export const resendCode = async (data, t = () => {}) => {
  try {
    const res = await phoneVerification(data);
    if (res?.status === 429 || res?.status === 400) {
      throw Error(t("errorMessage.tryLater"));
    } else if (res?.status === 200 || res?.status === 201) {
      return;
    } else if (res === "network error")
      throw Error(t("errorMessage.verifyConnection"));
  } catch (err) {
    throw err;
  }
};

export const resetPasswordCode = async (data, t = () => {}) => {
  try {
    const res = await getResetPasswordCode(data);
    if (res?.status === 201) {
      return;
    } else if (res?.status === 400) {
      if (res?.data?.["Details "].includes("No user with this phone.")) {
        throw Error(t("errorMessage.noUser"));
      } else if (
        res?.data?.["Details "].includes(
          "Phone number blocked due to multiple attempts, Please contact client services."
        )
      ) {
        throw Error(t("errorMessage.blockedPhone"));
      }
    } else if (res === "network error")
      throw Error(t("errorMessage.verifyConnection"));
  } catch (err) {
    throw err;
  }
};

export const verifyResetCode = async (data, t = () => {}) => {
  try {
    const res = await confrimResetPasswordCode(data);
    if (res.status === 200) {
      return;
    } else if (res?.status === 203) {
      if (res?.data?.details === "Invalid Code") {
        throw Error(t("errorMessage.codeInvalid"));
      }
    } else if (res === "network error")
      throw Error(t("errorMessage.verifyConnection"));
  } catch (err) {
    throw err;
  }
};

export const setNewPassword = async (data, t = () => {}) => {
  try {
    const res = await resetPassword(data);
    if (res?.status === 201) return;
    else if (res === "network error")
      throw Error(t("errorMessage.verifyConnection"));
    else throw Error(res?.details ?? res?.data?.details);
  } catch (err) {
    throw err;
  }
};

export const resetPasswordToken = async (data, t = () => {}) => {
  try {
    setAuthToken("no token");
    const res = await getResetPasswordToken(data);
    if (res?.status === 400) {
      if (Object.keys(res.data).includes("email")) {
        throw Error(t("errorMessage.noUserActive"));
      }
    } else if (res.status === 200) return;
    else if (res === "network error")
      throw Error(t("errorMessage.verifyConnection"));
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const setNewPasswordToken = async (data, t = () => {}) => {
  try {
    const res = await resetPasswordEmail(data);

    if (res.status === 201) return;
    else if (res.status === 404) throw Error(t("errorMessage.lienIncorrect"));
    else if (res.status === 400)
      if (
        res?.data?.password[0] ===
        "The password is too similar to the email address."
      )
        throw Error(t("errorMessage.similairPassword"));
      else throw Error(t("errorMessage.shortPassword"));
    else if (res === "network error")
      throw Error(t("errorMessage.verifyConnection"));
  } catch (err) {
    throw err;
  }
};

export const verifyRecaptchaToken = async (t = () => {}) => {
  try {
    let token = await window.grecaptcha.execute(
      process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      { action: "submit" }
    );
    const res = await checkRecaptchaToken({ "g-recaptcha-response": token });

    if (res.data?.success !== true || res?.data?.score < 0.5)
      throw Error(
        JSON.stringify({
          field: "recaptcha",
          msg: t("errorMessage.error"),
        })
      );
    else return true;
  } catch (_) {
    throw Error(
      JSON.stringify({
        field: "recaptcha",
        msg: t("errorMessage.error"),
      })
    );
  }
};
