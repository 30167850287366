import React from "react";

/**
 * Form
 * @description
 * @param {Function} props.onSubmit function to handle submit.
 * @param {JSX.Element} props.children elements inside the components.
 * @param {String} props.className style of form
 * @returns JSX.Element
 * @author
 */
export function Form({ onSubmit = () => {}, children, className }) {
  return (
    <form className={`my-2 w-full ${className}`} onSubmit={onSubmit}>
      {children}
    </form>
  );
}
