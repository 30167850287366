import axios from "./axios";
export const getCustomerInvite = (store, id) => {
  return axios
    .get(`/store/${store}/customers/confirmation/${id}/`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.message === "Network Error") return "network error";
      return error;
    });
};

export const getAllInvitations = () => {
  return axios
    .get(`/customer-invitations/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.message === "Network Error") return "network error";
      return error;
    });
};
export const deleteInvitation = (id) => {
  var config = {
    method: "delete",
    url: `/customer-invitations/`,
    headers: {
      Authorization: axios.defaults.headers.common["Authorization"],
      "Content-Type": "application/json",
    },
    data: { id: id },
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((errors) => {
      if (errors.message === "Network Error") return "network error";
      return errors.response;
    });
};
