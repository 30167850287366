import { getAllFeeds } from "../../api";

export const getFeeds = async (page, source) => {
  try {
    const response = await getAllFeeds(page, source);
    return response;
  } catch (e) {
    console.log(e);
    return [];
  }
};
