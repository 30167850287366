import { useEffect, useState } from "react";
export const useSortableTable = (data) => {
  const defaultData = data;
  const [tableData, setTableData] = useState(data);

  const [filterTracker, setFilterTracker] = useState([]);
  useEffect(() => {
    setTableData(data);
    setFilterTracker([]);
  }, [data]);
  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      if (sortOrder === "" && !filterTracker.length)
        return setTableData(defaultData);

      const sorted = [...tableData].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      setTableData(sorted);
    }
  };

  const checkFilter = (filter) => {
    const existingFilterIndex = filterTracker.findIndex(
      (f) => f.field === filter.field
    );

    if (existingFilterIndex !== -1) {
      if (filter.values.length !== 0) {
        filterTracker[existingFilterIndex].values = filter.values;
      } else {
        filterTracker.splice(existingFilterIndex, 1);
      }
    } else {
      filterTracker.push(filter);
    }
  };

  const setFilters = (filterOptions) => {
    checkFilter(filterOptions);

    if (filterTracker.length === 0) return setTableData(data);

    let filteredData = [...data];

    for (let filter of filterTracker) {
      filteredData = filteredData.filter((d) => {
        if (filter.values.map(String).includes(d[filter.field]?.toString())) {
          return true;
        }
        return false;
      });
    }

    setTableData(filteredData);
  };

  return [tableData, handleSorting, setFilters];
};
