import { CustomerInvitation } from "../pages/invitation/CustomerInvitation";
import { Switch, Route } from "react-router-dom";
import { ErrorPage } from "../components";
export default function CustomerInvitationNavigation(storeName, id) {
  let routes = (
    <Switch>
      <Route
        exact
        path={`/store/:storeName/customers/confirmation/:id`}
        render={({ match }) => (
          <CustomerInvitation
            storeName={match.params.storeName}
            id={match.params.id}
          />
        )}
      />
      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  );
  return routes;
}
