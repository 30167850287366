import React from "react";
import { useHistory } from "react-router-dom";
import { IconCard } from "../common/IconCard";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import { Button } from "../common/Button";
import { useTranslation } from "react-i18next";

export function RecoverStepone({ setRecoverStep, setMethod }) {
  const { t } = useTranslation();

  const history = useHistory();
  return (
    <>
      <div className="flex flex-row flex-auto justify-center items-center px-2 sm:py-8">
        <IconCard
          icon={<MailIcon className="w-8" />}
          title={t("auth.recoverByEmail")}
          onClick={() => {
            setMethod(0);
            setRecoverStep(1);
          }}
        />
        <IconCard
          icon={<PhoneIcon className="w-8" />}
          title={t("auth.recoverByPhone")}
          onClick={() => {
            setMethod(1);
            setRecoverStep(1);
          }}
        />
      </div>
      <div className="flex flex-col h-auto items-end justify-center">
        <Button
          fullWidth={false}
          type="secondary"
          responsiveFull={true}
          space
          onClick={() => history.push("/")}
        >
          {t("auth.backHome")}
        </Button>
      </div>
    </>
  );
}
