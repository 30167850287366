import { getSubscribedStores } from "../../api/";
import { saveStores as dispatchAction } from "../../redux/actions/";
export const getStores = async (source, index, dispatch) => {
  try {
    const { results, next } = await getSubscribedStores(source, index);
    dispatch(dispatchAction(results));
    return { results, next };
  } catch (e) {
    dispatch(dispatchAction([]));
    return { results: [], next: null };
  }
};

export const findStoreBySlug = (stores, slug) => {
  return stores
    .map(
      ({
        points,
        store: { id, slug, logo_src, name, description, store_url, created },
      }) => {
        return {
          id,
          points,
          slug,
          logo_src,
          name,
          description,
          store_url,
          created,
        };
      }
    )
    .find((store) => store.slug === slug);
};
