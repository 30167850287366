import React from "react";
import { useSelector } from "react-redux";
import { Item } from "../common/Item";
import { Space } from "../common/Space";
import { Text } from "../common/Text";
import { useTranslation } from "react-i18next";

export function Subscription({ backIcon }) {
  const { t } = useTranslation();
  const client = useSelector((state) => state.user);
  const subscription = client?.subscription;
  return (
    <>
      <div className="mb-4 flex">
        <span className="rtl:transform  rtl:rotate-180 rtl:self-start">
          {backIcon}
        </span>
        <Text weight="bold">{t("settings.subscription.yourSubscription")}</Text>
      </div>

      <Space top="4">
        <div className="pb-4">
          <Text color="text-gray-400" size="sm" weight="bold" uppercase>
            {t("settings.subscription.pack")}
          </Text>
        </div>

        <Item
          label={t("auth.name")}
          text={subscription?.pack?.name}
          onClick={null}
        />
        <Item
          label={t("settings.subscription.startDate")}
          text={subscription?.pack?.start_date?.split("T")[0]}
          onClick={null}
        />
        {client?.subscription?.pack?.name !== "Free" && (
          <Item
            label={t("settings.subscription.endDate")}
            text={
              subscription?.pack?.end_date?.split("T")[0] ??
              t("settings.subscription.dataNotFound")
            }
            onClick={null}
          />
        )}
      </Space>

      {subscription?.pos?.status && (
        <>
          <div className="my-2 h-px bg-gray-100 rounded-full" />

          <Space top="4">
            <div className="pb-4">
              <Text color="text-gray-400" size="sm" weight="bold" uppercase>
                {t("settings.subscription.pos")}
              </Text>
            </div>
            <Item
              label={t("auth.name")}
              text={subscription?.pos?.name}
              onClick={null}
            />
            <Item
              label={t("settings.subscription.startDate")}
              text={subscription?.pos?.start_date?.split("T")[0]}
              onClick={null}
            />
          </Space>
        </>
      )}
    </>
  );
}
