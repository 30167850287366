import { GridTile } from "./GridTile";

export const Grid = ({ items, onClick }) => {
  return (
    <div className="grid gap-8 row-gap-10 lg:grid-cols-3">
      {items.map((item) => {
        return (
          <GridTile
            key={item.id}
            id={item.slug}
            title={item.title}
            text={item.text}
            image={item.image}
            onClick={onClick}
            //   icon={<Archive />}
          />
        );
      })}
    </div>
  );
};
