import React from "react";

export function IconCard({ icon, title, onClick = () => {} }) {
  return (
    <div className="mx-4 ">
      <div
        className="w-16 flex flex-col shadow-inner ring-2 ring-gray-200 rounded-md p-4 mx-4 items-center cursor-pointer hover:opacity-50"
        onClick={onClick}
      >
        {icon}
      </div>
      <div className="text-center uppercase py-2 text-xs font-bold">
        {title}
      </div>
    </div>
  );
}
