import { Switch, Route } from "react-router-dom";
import Settings from "../pages/settings/Settings";
import { ErrorPage } from "../components";

export default function SettingsNavigation() {
  let routes = (
    <Switch>
      <Route exact path={`/settings`} component={Settings} />
      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  );

  return routes;
}
