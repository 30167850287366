import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../common/Button";
import { CodeInput } from "../common/CodeInput";
import { Banner } from "../common/Banner";
import { Space } from "../common/Space";
import { verifyResetCode } from "../../utils/services";
import { useTranslation } from "react-i18next";

export function RecoverStepthree({
  setRecoverStep,
  setConfirmationCode,
  phone,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [code, setCode] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const onSendCode = async () => {
    var data = {
      phone_number: phone,
      code: code.join(""),
    };
    try {
      if (code.join("").length === 6) {
        setLoading(true);
        await verifyResetCode(data, (x) => {
          return t(x);
        });
        setConfirmationCode(data?.code);
        setLoading(false);
        setRecoverStep(3);
      } else {
        setError(t("errorMessage.codeIncomplet"));
      }
    } catch (err) {
      setError(err?.message);
      setLoading(false);
    }
  };
  return (
    <>
      <Space top="2">
        {error && <Banner title={t("commons.error")} message={error} />}
      </Space>
      <div className="flex flex-col flex-auto justify-center items-center px-4 sm:py-8">
        <CodeInput
          code={code}
          setCode={setCode}
          onChange={() => setError("")}
        />
      </div>
      <div className="flex flex-col h-auto sm:flex-row sm:justify-end sm:items-center">
        <Button
          fullWidth={false}
          type="secondary"
          responsiveFull={true}
          space
          onClick={() => history.push("/")}
        >
          {t("auth.backHome")}
        </Button>
        <Button
          fullWidth={false}
          responsiveFull={true}
          space
          onClick={onSendCode}
          loading={loading}
        >
          {t("commons.send")}
        </Button>
      </div>
    </>
  );
}
