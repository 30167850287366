import axios from "axios";
import Cookies from "js-cookie";

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

export function isNetworkError(errors) {
  return (
    errors?.message === "Network Error" &&
    errors?.response?.status === undefined
  );
}

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  headers: {
    "Content-Type": "application/json",
  },
  params: {
    format: "json",
  },
});

instance.interceptors.response.use(
  (resp) => resp,
  (error) => {
    const originalRequest = error.config;
    let refresh_token = localStorage.getItem("refresh_token");

    if (
      [403, 401].includes(error.response?.status) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return refreshExpiredToken(refresh_token).then((res) => {
        if (res.status === 200) {
          var in10Hours = new Date(new Date().getTime() + 10 * 60 * 60 * 1000);
          Cookies.set("token", res.data.access_token, { expires: in10Hours });
          localStorage.setItem("refresh_token", res.data.refresh_token);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + res.data.access_token;

          return axios(originalRequest);
        }
      });
    }
    throw error;
  }
);

export const setAuthToken = (token) => {
  if (token && token !== "no token") {
    //applying token
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    //deleting the token from header
    delete instance.defaults.headers.common["Authorization"];
  }
};

export const refreshExpiredToken = (refresh_token) => {
  return axios
    .post("/connect/o/token/", {
      grant_type: "refresh_token",
      client_id: CLIENT_ID,
      refresh_token,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    })
    .then((responses) => {
      return responses;
    })
    .catch((errors) => {
      if (isNetworkError(errors)) return "network error";
      return errors.response;
    });
};

export default instance;
