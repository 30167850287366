import { getCustomerInvite } from "../../api";
import {
  getAllInvitations,
  deleteInvitation,
} from "../../api/invitation.instance";
export const isValidInvitation = async (store, id) => {
  const res = await getCustomerInvite(store, id);

  return res.status === 200 ? true : res;
};

export const getInvitation = async () => {
  try {
    const { accepted, pending } = await getAllInvitations();
    return { accepted, pending };
  } catch (e) {
    return { accepted: [], pending: [] };
  }
};
export const deleteInvitations = async (id) => {
  try {
    const response = await deleteInvitation(id);
    return response;
  } catch (e) {
    return [];
  }
};
