import { Switch, Route } from "react-router-dom";
import WishList from "../pages/wishlist/WishList";
import { ErrorPage } from "../components";

export default function WishListNavigation() {
  let routes = (
    <Switch>
      <Route exact path={`/wish-lists/all`} render={() => <WishList />} />

      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  );

  return routes;
}
