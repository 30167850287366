import React from "react";
import { ArrowCircleLeftIcon } from "@heroicons/react/outline";
import { Badge } from "../common/Badge";
import { useHistory } from "react-router";
import { Loading } from "../common/Loading";
import { ReactComponent as Notification } from "../../assets/images/NotificationsOutlined.svg";
import LoadingPage from "../../pages/common/Loading";
import { useTranslation } from "react-i18next";
import { getInvitation } from "../../utils/services/invitation.service";
import { useEffect, useState } from "react";
import ConfirmationAlert from "../common/ConfirmAlert";

/**
 * Text
 * @description A Page Component ready to use in order to
 * @param {object} props Page props.
 * @param {string} props.title The title that will be on top of the page. Represents the main purpose of the page.
 * @param {string} props.subtitle The subtitle that will be below the title of the page, can represent a hint or extra infos about the page.
 * @param {boolean} props.breadcrumbs Wether this is a subtree of navigation (in order to have the icon to go back)
 * @param {JSX.Element} props.meatada The page's metada, an element that is positonned in the right of the title, can be a badge or something else.
 * @param {JSX.Element} props.children elements inside the components.
 * @param {object} props.badge takes several elements for your badge.
 * @returns JSX.Element
 * @author @Jidmaa
 */

export default function Page({
  title = "",
  subtitle,
  breadcrumbs = false,
  badge,
  children,
  loading = false,
  fullwidth = false,
  notification = false,
}) {
  const history = useHistory();
  const goBack = () => history.goBack();
  const { t } = useTranslation();
  const [invitaions, setInvitations] = useState([]);

  useEffect(() => {
    getInvitation().then(({ pending }) => {
      setInvitations(pending ?? []);
    });
    return () => {
      setInvitations([]);
    };
  }, []);

  return (
    <>
      <div className="flex flex-col flex-1 ">
        <ConfirmationAlert></ConfirmationAlert>

        <div
          className={`${
            fullwidth ? "" : "px-8"
          } flex-1 overflow-y-auto relative flex flex-col`}
        >
          <Loading loading={loading} />

          <div className={`mb-10 ${fullwidth ? "px-8" : ""}`}>
            <div className=" h-16 justify-between space-between flex items-center ">
              <div className="flex flex-col">
                <div className="flex items-center">
                  {breadcrumbs && (
                    <ArrowCircleLeftIcon
                      className=" h-7 mr-3 cursor-pointer rtl:transform rtl:rotate-180"
                      onClick={goBack}
                    />
                  )}
                  <h1 className="mr-4 flex items-center text-3xl"> {title} </h1>
                  {badge && <Badge status={badge?.status}>{badge?.text}</Badge>}
                </div>
              </div>

              {notification && (
                <div
                  className="flex space-x-1 cursor-pointer"
                  onClick={() => {
                    history.push("/notification");
                  }}
                >
                  <div className="relative">
                    <Notification />
                    {invitaions.length !== 0 ? (
                      <div className=" absolute w-5 h-5 text-white text-xs bg-danger rounded-xl border-2 border-solid border-[#F9FAFB] -top-0.5 -left-1.5 ">
                        <div className=" flex justify-center ">
                          {invitaions.length}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {t("invitation.notification.notification")}
                </div>
              )}
            </div>
            <span className="text-xl opacity-50 ">{subtitle}</span>
          </div>
          <div className={`flex-1 w-full ${fullwidth ? "" : "mb-10"}`}>
            {loading ? <LoadingPage fullScreen={false} /> : children}
          </div>
        </div>
      </div>
    </>
  );
}
