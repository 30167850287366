import axios from "./axios";
import Axios from "axios";
export const getAllFeeds = (page, source) => {
  return axios
    .get(`/feed/?page=${page}`, {
      cancelToken: source?.token,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.message === "Network Error") return "network error";
      if (Axios.isCancel(error)) {
        return "cancelled";
      }
      return error.response;
    });
};
