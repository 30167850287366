import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Form } from "../common/Form";
import { InputText } from "../common/InputText";
import { InputPhone } from "../common/InputPhone";
import { Button } from "../common/Button";
import { Text } from "../common/Text";
import { resetPasswordCode, resetPasswordToken } from "../../utils/services";
import { MailIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";

export function RecoverSteptwo({
  setRecoverStep,
  method,
  setPhone,
  showEmailSent,
  setShowEmailSent,
}) {
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange" }); //form validation
  const { t } = useTranslation();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    data.phone_number = "+" + data.phone_number;
    if (method === 1) {
      try {
        setLoading(true);
        await resetPasswordCode(data, (x) => {
          return t(x);
        });
        setPhone(data?.phone_number);
        setLoading(false);
        setRecoverStep(2);
      } catch (err) {
        setError("phone_number", {
          type: "manual",
          message: err?.message,
        });
        setLoading(false);
      }
    } else if (method === 0) {
      try {
        setLoading(true);
        await resetPasswordToken(data, (x) => {
          return t(x);
        });
        setLoading(false);

        setShowEmailSent(true);
      } catch (err) {
        setError("email", {
          type: "manual",
          message: err?.message,
        });
        setLoading(false);
      }
    }
  };
  return (
    <>
      <Form
        className="flex flex-col flex-auto"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-row flex-auto justify-center items-center px-4 sm:py-8">
          {method === 0 ? (
            !showEmailSent ? (
              <InputText
                label={t("auth.email")}
                register={register}
                name="email"
                required
                type="email"
                pattern={{
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  message: t("errorMessage.invalidValue"),
                }}
                error={
                  errors?.email
                    ? ["manual", "pattern"].includes(errors?.email?.type)
                      ? errors?.email?.message
                      : t("auth.required")
                    : ""
                }
                className="flex-auto"
              />
            ) : (
              <div className="flex flex-col">
                <Text>{t("auth.checkMail")}</Text>
                <div className="flex justify-center">
                  <MailIcon className="w-32" />
                </div>
              </div>
            )
          ) : (
            <InputPhone
              name="phone_number"
              register={register}
              required
              control={control}
              label={t("auth.phoneNumber")}
              className="flex-auto"
              error={
                errors?.phone_number
                  ? ["manual", "pattern"].includes(errors?.phone_number?.type)
                    ? errors?.phone_number?.message
                    : t("auth.required")
                  : ""
              }
            />
          )}
        </div>
        <div className="flex flex-col h-auto sm:flex-row sm:justify-end sm:items-center">
          <Button
            fullWidth={false}
            type="secondary"
            responsiveFull={true}
            space
            onClick={() => history.push("/")}
          >
            {t("auth.backHome")}
          </Button>
          {!showEmailSent && (
            <Button
              fullWidth={false}
              responsiveFull={true}
              space
              disabled={Object.keys(errors).length !== 0}
              loading={loading}
            >
              {t("commons.send")}
            </Button>
          )}
        </div>
      </Form>
    </>
  );
}
