import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { UploadIcon } from "@heroicons/react/outline";
import { Text } from "./Text";
import { Space } from "./Space";
import { useTranslation } from "react-i18next";

export function Dropper({
  title,
  caption,
  files = [],
  setFiles,
  multiple = true,
}) {
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const thumbs = files.map((file) => (
    <div className="rounded my-2 ring ring-gray-200" key={file.name}>
      <div className="flex overflow-hidden">
        <img
          src={file.preview}
          alt="profil"
          className="rounded outline-none w-full"
        />
      </div>
    </div>
  ));
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <>
      <Text uppercase size="xs" color={"text-gray-400"}>
        {t("commons.profilPicture")}
      </Text>
      <section className="container w-full my-2">
        <div
          {...getRootProps({
            className:
              "dropzone w-full h-32 bg-gray-50 rounded-md outline-none ring-2 ring-gray-200 shadow rounded flex flex-col items-center justify-center relative cursor-pointer",
          })}
        >
          <input {...getInputProps()} />
          <UploadIcon className="h-6 w-6 text-gray-500 my-2" />
          <Text uppercase size="sm" weight="bold" color={"text-gray-500"}>
            {title}
          </Text>
          <Space vertical="px" />
          <Text uppercase size="xs" weight="light" color={"text-gray-500"}>
            {caption}
          </Text>
        </div>
        <aside className="my-2">
          <Text uppercase size="xs" color={"text-gray-400"}>
            {t("commons.files")}
          </Text>
          <ul>{thumbs}</ul>
        </aside>
      </section>
    </>
  );
}
