import { Switch, Route } from "react-router-dom";
import { Stores, StoreDetails } from "../pages/stores";
import { ErrorPage } from "../components";

export default function StoresNavigation() {
  let routes = (
    <Switch>
      <Route exact path={`/stores/all`} render={() => <Stores />} />
      <Route
        exact
        path={`/stores/:id`}
        render={({ match }) => <StoreDetails slug={match.params.id} />}
      />

      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  );

  return routes;
}
