import { Switch, Route } from "react-router-dom";
import Profile from "../pages/Profile/ProfileDetails";
import { ErrorPage } from "../components";

export default function ProfileNavigation() {
  let routes = (
    <Switch>
      <Route
        exact
        path={`/user/:id`}
        render={({ match }) => <Profile username={match.params.id} />}
      />
      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  );

  return routes;
}
