import { UserCircleIcon } from "@heroicons/react/outline";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import kioPrimary from "../../assets/images/kio-primary.svg";
import { useDate } from "../../hooks/";
import { getOrders } from "../../utils/services";
import { Badge } from "../common/Badge";
import { useTranslation } from "react-i18next";
import FlaggedSelect from "../common/FlaggedSelect";

export const LeftPanel = ({ elements }) => {
  const location = useLocation();
  const history = useHistory();
  const [selected, setSelected] = useState(location.pathname);
  const fromStore = window.localStorage.getItem("fromStore");
  const { t } = useTranslation();

  return (
    <div className="relative h-full max-h-full border-r  rtl:border-l border-gray-300 flex flex-col overflow-y-auto">
      <div className="px-8 sm:px-10 h-16 flex items-center">
        <Link
          to="/"
          onClick={() => {
            setSelected("/");
          }}
        >
          <span className="sr-only">{t("layout.kioLogo")} </span>
          <img src={kioPrimary} alt="kio's logo" className="h-8 w-10 sm:h-10" />
        </Link>
      </div>
      <div className="w-72 flex-1 py-2">
        <nav className="w-full h-full flex flex-col">
          {elements.map((navigation, index) => {
            return (
              <ul
                className={`${navigation.primary ? "flex-1" : ""}`}
                key={index}
              >
                {navigation.children.map(({ key, onClick, icon, title }) => {
                  return (
                    <div
                      className={`h-14 flex items-center rounded-3xl mx-4 px-4 sm:px-6 cursor-pointer transition-colors duration-200 text-gray-600 hover:text-gray-100 hover:bg-kio-500 hover:bg-opacity-95 ${
                        selected === key ? "text-kio-500" : ""
                      }`}
                      onClick={() => {
                        if (onClick) {
                          onClick();
                          return;
                        }
                        setSelected(key);
                        history.push(key);
                      }}
                      key={key}
                    >
                      {icon}
                      <span
                        className={`mx-4 ${
                          fromStore ? "text-meduim" : "text-lg"
                        } font-normal`}
                      >
                        {title}
                      </span>
                      <span className="flex-grow"></span>
                    </div>
                  );
                })}
              </ul>
            );
          })}
        </nav>
      </div>
    </div>
  );
};
function StockRightPanel() {
  var today = useDate();
  const client = useSelector((state) => state.user);

  const subscription = client?.subscription;
  const [orders, setOrders] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const source = axios.CancelToken.source();
    getOrders(1, source).then((resp) => {
      setOrders(resp?.count ?? 0);
    });
    return () => {
      source.cancel();
    };
  }, []);

  return (
    <div className="z-0 h-full right-2 top-0 overflow-y-auto">
      <div className="relative h-full max-h-full border-l rtl:border-r border-gray-300 flex flex-col">
        <div className="px-8 sm:px-10 h-16 flex items-center justify-between">
          <span className="sr-only"> {t("layout.todayDate")} </span>
          <span className="text-lg font-extrabold rtl:ltr-text">
            {new Intl.DateTimeFormat("fr-FR", {
              year: "numeric",
              month: "long",
              day: "2-digit",
            }).format(Date.parse(today))}
          </span>
          <FlaggedSelect></FlaggedSelect>
        </div>
        <div className="w-72 flex-1 py-2 px-6">
          <div className="w-full h-64 px-2 sm:px-4 py-4 bg-white rounded-md outline-none ring-2 ring-gray-200 flex flex-col justify-evenly items-center shadow">
            <div className="block relative">
              {client?.avatar_src ? (
                <img
                  alt="profil"
                  src={client?.avatar_src ?? ""}
                  className="object-cover rounded-full h-16 w-16 "
                />
              ) : (
                <UserCircleIcon className="object-cover rounded-full h-16 w-16" />
              )}
              <span className="absolute w-3 border-2 left-1/2 -bottom-2 transform -translate-x-1/2 border-white h-3 bg-green-500 rounded-full"></span>
            </div>

            <div className="text-lg font-medium w-full text-center mt-2.5 break-words ">
              {client?.full_name ?? ""}
            </div>

            <div className="text-lg font-medium ">
              {orders} {t("orders.orders")}
            </div>
            <div className="my-2 h-px bg-gray-100 rounded-full" />
          </div>

          <div className="w-full px-2 sm:px-4 text-gray-400 text-sm flex flex-wrap my-4">
            <div className="cursor-pointer">{t("layout.termUse")}</div>
            <div className="cursor-pointer"> {t("layout.privacyPolicy")}</div>
            <div className="cursor-pointer">{t("layout.kio2021")} </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export const RightPanel = React.memo(StockRightPanel);
