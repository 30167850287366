import { isValidInvitation } from "../../utils/services";
import { Page } from "../../components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import invitationSuccess from "../../assets/images/invitationSuccess.svg";
import NotFound from "../../assets/images/404.svg";
export function CustomerInvitation({ storeName, id }) {
  const [loading, setLoading] = useState(true);
  const [invitationStatus, setInvitationStatus] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    isValidInvitation(storeName, id).then((res) => {
      setLoading(false);

      if (res === true) {
        setInvitationStatus(res);
        return;
      }
      if (res === "network error")
        setError(t("errorMessage.noInternetConnection"));
      if (res?.response?.status === 404) return;
      if (res?.response?.data) {
        setError(res.response?.data?.non_field_errors[0]);
      }
    });
  }, [storeName, id]); // eslint-disable-line
  return (
    <>
      <Page
        loading={loading}
        title={`${t("invitation.storeInvitation")} ${storeName}`}
      >
        <div className="justify-center items-center flex flex-col h-full">
          {invitationStatus === true ? (
            <>
              <img
                className="h-3/4 w-3/4"
                src={invitationSuccess}
                alt="Invitation succeeded"
              />
              <p className="text-2xl">
                {t("invitation.yourInvitation")} {storeName}
                {t("invitation.wasSucces")}
              </p>
            </>
          ) : (
            <>
              <img
                className="h-3/4 w-3/4"
                src={NotFound}
                alt="Invitation succeeded"
              />
              <p className="text-2xl">
                {error ? error : t("invitation.invalidLink")}
              </p>
            </>
          )}
        </div>
      </Page>
    </>
  );
}
