import React from "react";

/**
 * Banner
 * @description
 * @param {object} props Text props.
 * @param {string} props.type type of banner: error - warning.
 * @param {string} props.title banner title.
 * @param {string} props.message banner description.
 * @returns JSX.Element
 * @author
 */
export function Banner({ type = "error", title, message }) {
  return (
    <div className={`${BANNER_STYLES[type]} border-l-4 p-4`}>
      <p className="font-bold">{title}</p>
      <p>{message}</p>
    </div>
  );
}

const BANNER_STYLES = {
  error: "bg-red-100  border-red-500 text-red-700",
  warning: "bg-orange-100 border-orange-500 text-orange-700",
};
