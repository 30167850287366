import { Text } from "./Text";
import { LibraryIcon } from "@heroicons/react/outline";

export const GridTile = ({ id, title, text, image, onClick }) => {
  return (
    <div
      key={id}
      onClick={() => {
        if (onClick) onClick(id);
      }}
      className="px-2 sm:px-4 py-6 bg-white rounded-lg outline-none shadow ring-2 ring-gray-200 max-w-full lg:max-w-md text-center cursor-pointer transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-md"
    >
      <div className="w-100 flex items-center justify-center">
        {image ? (
          <img
            className="flex items-center justify-center w-16 h-16 mb-4 rounded-full sm:mx-auto sm:w-24 sm:h-24 border border-gray-200"
            src={image}
            alt={title}
          />
        ) : (
          <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full sm:mx-auto sm:w-24 sm:h-24 border border-gray-200">
            <LibraryIcon className="h-8 w-8 text-gray-600" />
          </div>
        )}
      </div>
      <div className="mb-3">
        <Text size="xl2" weight="bold">
          {title}
        </Text>
      </div>
      <div className="mb-3">
        <Text size="base" weight="medium">
          {text}
        </Text>
      </div>
    </div>
  );
};
