import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Text } from "../common/Text";
import { Button } from "../common/Button";
import { Counter } from "../common/Counter";
import { resendCode, logout } from "../../utils/services";
import { Space } from "../common/Space";
import { Banner } from "../common/Banner";
import { useTranslation } from "react-i18next";
import { getValueToConfirm } from "../utils";

export function StepTwo({
  showTimer,
  setShowTimer,
  setConfirmationStep,
  method,
  toConfirm,
}) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState("");
  const [innerLoading, setInnerLoading] = useState(false);
  const { t } = useTranslation();

  const onResendCode = async () => {
    setInnerLoading(true);
    let data = {
      email: method === 0 ? getValueToConfirm("email", user) : null,
      phone_number: method === 0 ? null : getValueToConfirm("phone", user),
      confirmation_code_email: null,
      confirmation_code_phone: null,
      resend: true,
    };
    try {
      await resendCode(data, (x) => {
        return t(x);
      });
      setShowTimer(false);
      setInnerLoading(false);
      setConfirmationStep(2);
    } catch (err) {
      setShowTimer(false);
      setInnerLoading(false);
      setError(err?.message);
    }
  };

  return (
    <>
      <Text size="sm" color="text-gray-400">
        {t("auth.confirmMessage")}
      </Text>
      <Space vertical="2">
        {error && (
          <Banner type="error" title={t("commons.error")} message={error} />
        )}
      </Space>

      <div className="flex mt-12 flex-col sm:flex-row">
        <div className="flex-auto">
          <Text size="xs">
            {method === 0 ? t("auth.yourEmail") : t("auth.yourPhone")}
          </Text>
          <Text
            size="sm"
            weight="bold"
            className={
              toConfirm === "phone" ? "rtl:ltr-text rtl:text-right" : ""
            }
          >
            {method === 0
              ? getValueToConfirm("email", user)
              : getValueToConfirm("phone", user)}
          </Text>
        </div>
        <div className="flex-auto flex flex-row justify-end items-end py-4 sm:py-0">
          {toConfirm ? (
            <Button
              fullWidth={false}
              responsiveFull={true}
              disabled={showTimer}
              type="secondary"
              space
              onClick={() => history.push("/")}
            >
              {t("commons.previous")}
            </Button>
          ) : (
            <Button
              fullWidth={false}
              responsiveFull={true}
              disabled={showTimer}
              type="secondary"
              space
              onClick={() => logout(history, dispatch)}
            >
              {t("auth.deconnect")}
            </Button>
          )}

          {!showTimer && (
            <Button
              fullWidth={false}
              onClick={() => {
                setConfirmationStep(2);
              }}
              responsiveFull={true}
              space
              loading={innerLoading}
            >
              {t("auth.continue")}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
