const initialState = {
  language: "fr",
};

export default function languageReducer(state = initialState, action) {
  switch (action.type) {
    case "changeLanguage":
      return {
        language: action.preload,
      };
    default:
      return state;
  }
}
// export default orderReducer;
