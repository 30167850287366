import { Page, Pagination, EmptyState, List } from "../../components";
import { useState, useEffect } from "react";
import Axios from "axios";
import { getWishes } from "../../utils/services";
import { useTranslation } from "react-i18next";
export default function WishList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState(1);
  const [wishes, setWishes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const source = Axios.CancelToken.source();
    setInnerLoading(true);
    getWishes(currentPage, source).then(({ results, count }) => {
      setWishes(results ?? []);
      setPageNumbers(count > 20 && Math.ceil(count / 20));
      setInnerLoading(false);
      setLoading(false);
    });
    return () => {
      source.cancel("component got unmounted");
    };
  }, [currentPage]);
  useEffect(() => {
    return () => {
      setWishes([]);
    };
  }, []);
  return (
    <>
      <Page
        title={t("wishList.wishList")}
        subtitle={t("wishList.wishListMsg")}
        loading={loading}
        notification={true}
      >
        {wishes.length === 0 ? (
          <EmptyState text={t("wishList.NoWishListText")} />
        ) : (
          <>
            <List
              items={wishes?.map(
                ({
                  id,
                  product: { media, category, name, variants, url },
                  store: { name: storeName },
                }) => {
                  return {
                    id: id,
                    src: media[0]?.src,
                    description: category,
                    title: name,
                    secondary: `${variants[0]?.price} ${variants[0]?.price_currency}`,
                    caption: storeName,
                    url: url,
                  };
                }
              )}
              loading={innerLoading}
            />
            <Pagination
              currentPage={currentPage}
              pageNumbers={pageNumbers}
              setCurrentPage={setCurrentPage}
            />
          </>
        )}
      </Page>
    </>
  );
}
