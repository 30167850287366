import React, { useEffect, useState } from "react";
import { DesktopComputerIcon } from "@heroicons/react/outline";
import { userDevices } from "../../utils/services";
import { Text } from "../common/Text";
import { Space } from "../common/Space";
import DeviceItem from "../common/DeviceItem";
import { EmptyState } from "..";
import { useTranslation } from "react-i18next";
export function Security({ setLoading, backIcon }) {
  const { t } = useTranslation();
  const [deviceList, setDeviceList] = useState([]);
  useEffect(() => {
    userDevices()
      .then((res) => {
        setDeviceList(res ?? []);
      })
      .catch((_) => {});
  }, []);
  return (
    <div>
      <div className="mb-4 flex">
        <span className="rtl:transform  rtl:rotate-180 rtl:self-start">
          {backIcon}
        </span>
        <Text weight="bold"> {t("settings.security.yourDevices")} </Text>
      </div>
      <Text size="xs" color="text-gray-300">
        {t("settings.security.connectedDevices")}
      </Text>
      <Space top="4">
        {deviceList?.map((device) => (
          <DeviceItem
            icon={<DesktopComputerIcon className="w-10 text-kio-500" />}
            deviceName={device?.name}
            systemName={device?.system_name}
            systemVersion={device?.system_version}
            brand={device?.brand}
            model={device?.model}
            appversion={device?.app_version}
            ipAddress={device?.ip_address}
            macAddress={device?.mac_address}
          />
        ))}
        {deviceList?.length === 0 && (
          <EmptyState
            title={t("settings.security.noDevices")}
            text={t("settings.security.noDevicesMsg")}
          />
        )}
      </Space>
    </div>
  );
}
