import React from "react";
import { Text } from "./Text";
import { useTranslation } from "react-i18next";
export default function DeviceItem({
  icon,
  deviceName,
  systemName,
  systemVersion,
  ipAddress,
  macAddress,
  appversion,
  brand,
  model,
}) {
  const { t } = useTranslation();
  return (
    <div className="p-4 border rounded-md mb-2">
      <div className="flex flex-row">
        {icon}
        <div className="flex flex-col ml-2 flex-1">
          <div>
            <Text size="sm" weight="bold">
              {deviceName}
            </Text>
            <Text size="xs" weight="bold" uppercase>
              {t("settings.security.system") +
                systemName +
                " " +
                (systemVersion ?? "")}
            </Text>
            <Text size="xs" weight="bold" uppercase>
              {t("settings.security.applicationVersion") + appversion}
            </Text>
          </div>
          <div className="flex sm:flex-row flex-col">
            <div className="flex-1">
              <Text size="xs" weight="bold">
                <span className="uppercase">
                  {t("settings.security.deviceBrand")}
                </span>
                {brand}
              </Text>
            </div>
            <div className="flex-1">
              <Text size="xs" weight="bold">
                <span className="uppercase">
                  {t("settings.security.deviceModel")}
                </span>
                {model}
              </Text>
            </div>
          </div>
          <div className="flex sm:flex-row flex-col">
            <div className="flex-1">
              <Text size="xs" weight="bold">
                <span className="uppercase">
                  {t("settings.security.ipAddress")}
                </span>
                {ipAddress}
              </Text>
            </div>
            <div className="flex-1">
              <Text size="xs" weight="bold">
                <span className="uppercase">
                  {t("settings.security.macAddress")}
                </span>
                {macAddress}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
