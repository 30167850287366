export function getQueryParams() {
  const queryString = window.location.search;
  const params = {};

  if (queryString) {
    queryString
      .substring(1)
      .split("&")
      .forEach((pair) => {
        const [key, value] = pair.split("=");
        params[decodeURIComponent(key)] = decodeURIComponent(value || "");
      });
  }

  return params;
}
