import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, EmptyState, Modal, Page, PostCard } from "../../components";
import { getFeeds } from "../../utils/services";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import { getInvitation } from "../../utils/services/invitation.service";

export default function Home() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState(1);
  const [loading, setLoading] = useState(false);
  const [feeds, setFeeds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFeed, setSelectedFeed] = useState({});
  const { t } = useTranslation();
  const [, setInvitations] = useState([]);
  let source;
  const getData = async () => {
    setLoading(true);

    source = axios.CancelToken.source();
    await getFeeds(currentPage, source).then((res) => {
      let results = res?.results ? res?.results : [];
      setFeeds((f) => [...f, ...results]);
      setPageNumbers(Math.ceil(res?.count / 20));
    });
    await getInvitation().then(({ pending }) => {
      setInvitations(pending ?? []);
      setLoading(false);
    });
    setLoading(false);
  };

  useEffect(() => {
    getData();
    return () => {
      source.cancel();
    };
  }, [currentPage]); // eslint-disable-line
  useEffect(() => {
    return () => {
      setFeeds([]);
    };
  }, []);
  return (
    <Page title={t("home.home")} notification={true}>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={selectedFeed?.name}
        button={false}
      >
        <div>
          <div className="font-bold text-kio-500">
            {t("home.productContains")}
            {selectedFeed?.variants?.length === 1
              ? selectedFeed?.variants[0]?.attributes?.length === 0
                ? t("home.0variantes")
                : t("home.1variantes")
              : selectedFeed?.variants?.length + t("home.variantes")}
          </div>
          <div className="max-h-32 my-2 overflow-y-auto custom-scroll">
            <ReactMarkdown>{selectedFeed?.description}</ReactMarkdown>
          </div>
          <div className="mt-4">
            <div className="w-full flex justify-end">
              <Button
                onClick={() => setIsOpen(false)}
                type="secondary"
                size="small"
                fullWidth={false}
              >
                {t("commons.close")}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {feeds.length === 0 && !loading && (
        <EmptyState title={t("home.noArticle")} text={t("home.followStores")} />
      )}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        {feeds.map((feed, index) => (
          <PostCard
            key={"post-" + index}
            loading={false}
            storeName={feed?.store?.name}
            postImage={feed?.media?.length > 0 ? feed?.media[0].src : null}
            description={feed?.description}
            productName={feed?.name}
            avatarSrc={feed?.store?.logo_src}
            onClick={() => {
              window.open(
                feed?.store?.store_url + "/products/" + feed?.slug + "/",
                "_blank"
              );
            }}
            onShowMore={() => {
              setSelectedFeed(feed);
              setIsOpen(true);
            }}
          />
        ))}

        {loading &&
          [...Array(6)].map((_, index) => (
            <PostCard key={"post-loading-" + index} loading={true} />
          ))}
      </div>
      {currentPage < pageNumbers && (
        <div className="w-full flex justify-center py-4">
          <Button
            fullWidth={false}
            size={"small"}
            type="secondary"
            onClick={() => {
              setCurrentPage(currentPage + 1);
            }}
          >
            {t("home.loadMore")}
          </Button>
        </div>
      )}
    </Page>
  );
}
