import React from "react";
import { ReactComponent as StoreSvg } from "../../assets/images/Vector.svg";
import { useTranslation } from "react-i18next";
import { deleteInvitations } from "../../utils/services/invitation.service";
import { isValidInvitation } from "../../utils/services";

export function Notification({ item, onAction = () => {} }) {
  const { t } = useTranslation();

  const accept = () => {
    isValidInvitation(item?.store?.slug, item?.token).then((res) => {
      if (res === "network error" || res?.response?.status === 404) return;
      onAction();
    });
  };
  const ignore = () => {
    deleteInvitations(item?.id).then((res) => {
      onAction();
    });
  };
  return (
    <div className="h-[60px]  bg-white rounded-lg border flex w-full px-4 py-2 justify-between">
      <div className="flex items-center">
        <StoreSvg />
        <span className=" text-kio-500 mx-1">{item.store.name} </span>{" "}
        {t("invitation.notification.joinShop")}
      </div>
      <div className=" flex space-x-2">
        <button className="px-2 py-1 text-darkGray-1" onClick={ignore}>
          {t("invitation.notification.ignore")}
        </button>
        <button
          className="px-2 py-1 bg-kio-500 rounded-lg text-white"
          onClick={accept}
        >
          {t("invitation.notification.accept")}
        </button>
      </div>
    </div>
  );
}
