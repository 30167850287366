import axios from "./axios";

export const getAllAddresses = (source) => {
  return axios
    .get("/connect/user/addresses", {
      cancelToken: source.token,
    })
    .then((response) => response.data)
    .catch((errors) => {
      if (errors.message === "Network Error") return "network error";
      return errors.response;
    });
};

export const createAddress = (myData) => {
  return axios
    .post("/connect/user/addresses/", myData)
    .then((responses) => {
      return responses;
    })
    .catch((errors) => {
      return errors.response;
    });
};
export const updateAddress = (myData) => {
  return axios
    .put("/connect/user/addresses/", myData)
    .then((responses) => {
      return responses;
    })
    .catch((errors) => {
      return errors.response;
    });
};
export const deleteAddresseById = (id) => {
  var config = {
    method: "delete",
    url: "/connect/user/addresses/",
    headers: {
      Authorization: axios.defaults.headers.common["Authorization"],
      "Content-Type": "application/json",
    },
    data: { id: id },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((errors) => {
      if (errors.message === "Network Error") return "network error";
      return errors.response;
    });
};

export const getCountries = (source) => {
  return axios
    .get("/core/countries/", {
      cancelToken: source?.token,
    })
    .then((response) => {
      return response.data;
    })
    .catch((errors) => {
      return errors.response;
    });
};

export const getStates = (country, source) => {
  return axios
    .get(`/core/countries/${country}/states/`, {
      cancelToken: source.token,
    })
    .then((response) => {
      return response.data;
    })
    .catch((errors) => {
      return errors.response;
    });
};

export const getCities = (country, state, source) => {
  return axios
    .get(`/core/countries/${country}/states/${state}/cities/`, {
      cancelToken: source.token,
    })
    .then((response) => {
      return response.data;
    })
    .catch((errors) => {
      return errors.response;
    });
};
