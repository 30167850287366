import React from "react";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";

export function PostCard({
  storeName,
  avatarSrc,
  postImage,
  description,
  productName,
  loading = false,
  onShowMore = () => {},
  onClick = () => {},
}) {
  const { t } = useTranslation();

  return (
    <div className="bg-white rounded p-4">
      <div className="flex flex-row">
        <div
          style={{ backgroundImage: `url("${avatarSrc}")` }}
          className={`w-16 h-16 rtl:ml-2 bg-gray-200 bg-center bg-no-repeat bg-cover rounded-full ${
            loading && "animate-pulse"
          }`}
        ></div>
        <div className="truncate w-48 flex flex-col justify-center pl-2">
          {loading ? (
            <>
              <div className="bg-gray-200 w-24 h-2 animate-pulse"></div>
              <div className="bg-gray-200 w-16 h-2 animate-pulse mt-1"></div>
            </>
          ) : (
            <>
              <div>{storeName}</div>
              <div
                className="text-xs underline cursor-pointer hover:text-kio-500"
                onClick={onClick}
              >
                {t("home.seeInStore")}
              </div>
            </>
          )}
        </div>
      </div>
      <div
        style={{ backgroundImage: `url("${postImage}")` }}
        className={`mt-2 w-full h-52 bg-gray-200 bg-center bg-no-repeat bg-cover ${
          loading && "animate-pulse"
        }`}
      ></div>
      <div className="flex flex-col pt-2">
        {loading ? (
          <>
            <div className="bg-gray-200 w-24 h-2 animate-pulse"></div>
            <div className="bg-gray-200 w-full h-2 animate-pulse mt-1"></div>
            <div className="bg-gray-200 w-full h-2 animate-pulse mt-1"></div>
            <div className="bg-gray-200 w-full h-2 animate-pulse mt-1"></div>
          </>
        ) : (
          <>
            <div className="text-base font-bold capitalize truncate">
              {productName}
            </div>
            <div className="text-sm text-justify truncate-3 h-16">
              <ReactMarkdown>
                {description === "" ? t("home.noDescription") : description}
              </ReactMarkdown>
            </div>
          </>
        )}
      </div>
      {loading ? (
        <div className="bg-gray-200 mt-2 animate-pulse h-12"></div>
      ) : (
        <div
          className="text-center py-2 mt-2 bg-gray-50 cursor-pointer hover:bg-gray-100"
          onClick={onShowMore}
        >
          {t("home.seeMore")}
        </div>
      )}
    </div>
  );
}
