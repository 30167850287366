import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect,
} from "react-router-dom";

import Signin from "./pages/auth/Signin";
import { MainLayout } from "./pages/layout/MainLayout";
import { useTranslation } from "react-i18next";
import Signup from "./pages/auth/Signup";
import RecoverAccount from "./pages/auth/RecoverAccount";
import Loading from "./pages/common/Loading";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { verifyCookie, getUserInformations } from "./utils/services/";
import { setAuthToken } from "./api/";
import { ErrorPage, RouteChangeTracker } from "./components";
import {
  Orders,
  Profile,
  Stores,
  WishList,
  Settings,
  Home,
  // Explore,
  //Messages,
  CustomerInvitation,
} from "./routes";
import ConfirmAccount from "./pages/auth/ConfirmAccount";

import ReactGA from "react-ga";
import { Notifications } from "./pages/invitation/Notification";

function App() {
  const dispatch = useDispatch(); // variable to change data in the redux store
  const selectedLanguage = useSelector((state) => state.language).language;
  const { i18n } = useTranslation();

  /*Token*/
  const [token, setToken] = useState(Cookies.get("token") ?? ""); // the actual token;
  /*Token*/

  const [fetchingToken, setFetchingToken] = useState(true); // a variable that checks wheter we are refreshing the token or not

  /*User Data*/
  const user = useSelector((state) => state.user);

  const history = useHistory();
  /*Language*/
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]); // eslint-disable-line
  /*User data*/
  useEffect(() => {
    let cookieToken = Cookies.get("token");
    let refreshToken = localStorage.getItem("refresh_token");
    verifyCookie(
      cookieToken,
      refreshToken,
      setToken,
      setFetchingToken,
      dispatch
    );
  }, [dispatch]);

  useEffect(() => {
    ReactGA.initialize("UA-161774612-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [history]);

  //RouteChangeTracker();
  if (!["", undefined, null].includes(token)) {
    setAuthToken(token);
if (Object.keys(user).length === 0)
      getUserInformations(dispatch).catch((e) => console.log(e));

  }

  let routes = <></>;

  if (["no token", "", undefined, null].includes(token)) {
    routes = (
      <Switch>
        <Route exact path="/">
          <Signin />
        </Route>
        <Route exact path="/signup">
          <Signup />
        </Route>
        <Route exact path="/recover-account">
          <RecoverAccount />
        </Route>
        <Route
          exact
          path={`/recover-account/:id`}
          render={({ match }) => <RecoverAccount token={match.params.id} />}
        />
        <Route exact path={`/store/:storeName/customers/confirmation/:id`}>
          <Redirect to="/" />
        </Route>
        <Route path="*">
          <ErrorPage />
        </Route>
      </Switch>
    );
  } else {
    if (Object.keys(user ?? {})?.length === 0) {
      routes = <Loading />;
    } else {
      if (user?.isconfirmed)
        routes = (
          <MainLayout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/orders" component={Orders} />
              {/* <Route path="/inbox" component={Messages} /> */}
              <Route path="/notification" component={Notifications} />
              {/* <Route path="/explore" component={Explore} /> */}
              <Route path="/stores" component={Stores} />
              <Route path="/wish-lists" component={WishList} />
              <Route path="/user" component={Profile} />
              <Route path="/settings" component={Settings} />

              <Route path="/store" component={CustomerInvitation} />
              <Route path="*">
                <ErrorPage />
              </Route>
            </Switch>
          </MainLayout>
        );
      else routes = <Route path="/" component={ConfirmAccount} />;
    }
  }

  useEffect(() => {
    token &&
      window.location.pathname.includes("recover-account") &&
      setFetchingToken(true)((window.location.pathname = "/settings"));
  }, [window.location.pathname]); // eslint-disable-line
  return (
    <div className="w-full	h-full overflow-x-hidden">
      {fetchingToken ? (
        <Loading />
      ) : (
        <Router basename="/profile">
          <Route path={`/confirm-account`}>
            <ConfirmAccount></ConfirmAccount>
          </Route>

          <RouteChangeTracker history={history} />

          {routes}
        </Router>
      )}
    </div>
  );
}

export default App;
