import React, { useState, useEffect } from "react";
import { Text } from "./Text";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Controller } from "react-hook-form";
import { getCountryIso } from "../../api/countryIso";

/**
 * InputText
 * @description
 * @param {object} props InputText props.
 * @param {string} props.label text of the input label.
 * @param {Element.JSX} props.control hook form control
 * @param {string} props.error input error text
 * @param {string} props.name name of the input
 * @param {boolean} props.required field required
 * @param {JSX} props.register useForm register
 * @param {string} props.underText text under input field
 *  @param {string} props.defaultValue input default value
 * @returns JSX.Element
 * @author
 */
export function InputPhone({
  label = "",
  underText = "",
  error = "",
  control,
  required = false,
  className = "",
  textClassName = "",
  defaultValue = "",
}) {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    getCountryIso()
      .then((res) => {
        setCountries(res);
      })
      .catch((err) => console.error(err));
  }, []);
  return (
    <div className={`flex flex-col my-2 ${className}`}>
      {countries.length > 0 && (
        <>
          <Text
            className={textClassName}
            uppercase
            size="xs"
            color={error === "" ? "text-gray-400" : "text-red-500"}
          >
            {label}
            {error === "" ? (
              ""
            ) : (
              <span style={{ textTransform: "initial" }} className="italic">
                {" - " + error}
              </span>
            )}
          </Text>
          <div>
            <div>
              <Controller
                render={(props) => {
                  return (
                    <PhoneInput
                      country={"dz"}
                      localization={"fr"}
                      onlyCountries={countries}
                      countryCodeEditable={false}
                      value={props.field.value}
                      inputStyle={{
                        fontSize: "0.875rem",
                        lineHeight: "1.25rem",
                        borderRadius: "0.375rem",
                        height: "2.75rem",
                        outline: "2px solid transparent",
                        width: "100%",
                      }}
                      buttonStyle={{
                        borderTopLeftRadius: "0.375rem",
                        borderBottomLeftRadius: "0.375rem",
                      }}
                      buttonClass={`${
                        error === ""
                          ? "ring-indigo-200 border-phone"
                          : "ring-red-200 border-phone-error"
                      }`}
                      inputClass={`outline-none w-full min-w-full text-sm p-2  rounded-md shadow-sm border focus:outline-none focus:ring-2 h-full min-h-full 
                              ${
                                error === ""
                                  ? "ring-indigo-200 border-phone"
                                  : "b ring-red-200 border-phone-error"
                              }`}
                      containerClass={`w-full my-2 h-11 rtl:ltr-text  ${
                        error === ""
                          ? "focus:border-indigo-300 border-gray-300 ring-indigo-200"
                          : "border-red-500 ring-red-200 focus:border-red-300"
                      }`}
                      inputProps={{
                        id: "phone_number",
                        name: "phone_number",
                        required,
                      }}
                      onChange={(phone) => {
                        props.field.onChange(phone);
                      }}
                    />
                  );
                }}
                rules={{
                  name: "phone_number",
                  required: required,
                }}
                name="phone_number"
                control={control}
                defaultValue={defaultValue}
              />
            </div>
          </div>
          {underText}
        </>
      )}
    </div>
  );
}
