import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { isObjectEmpty, translateFilterItems } from "../utils";
import { useTranslation } from "react-i18next";

import { ReactComponent as FilterIcon } from "../../assets/images/filter.svg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Filter({ filterData, accessor, onFilter }) {
  const { t } = useTranslation();
  const filters = [
    {
      id: accessor ?? "",
      name: accessor ?? "",
      options: filterData?.map((item) => ({
        value: item,
        label: item,
        checked: false,
      })),
    },
  ];
  const [checkedOptions, setCheckedOptions] = useState({});

  useEffect(() => {
    setCheckedOptions({});
  }, [filterData]);
  const handleCheckboxChange = (option) => {
    setCheckedOptions({
      ...checkedOptions,
      [option.value]: !checkedOptions[option.value],
    });

    onFilter({
      field: accessor,
      values: Object.entries({
        ...checkedOptions,
        [option.value]: !checkedOptions[option.value],
      })
        .filter(([key, value]) => value === true)
        .map(([key, value]) => key),
    });
  };

  return (
    <div className="ml-2 mt-[5px]">
      <section aria-labelledby="filter-heading">
        <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
          {filters.map((section, sectionIdx) => (
            <Popover
              key={section.name}
              className="relative inline-block px-4 text-left"
            >
              <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900 outline-none">
                <FilterIcon
                  className={classNames(
                    !isObjectEmpty(checkedOptions) && "text-blue-600",
                    "mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  )}
                ></FilterIcon>
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="space-y-4">
                    {section.options.map((option, optionIdx) => (
                      <div key={option.value} className="flex items-center">
                        <input
                          id={`filter-${section.id}-${optionIdx}`}
                          name={`${section.id}[]`}
                          defaultValue={option.value}
                          type="checkbox"
                          checked={checkedOptions[option.value] || false}
                          onChange={() => handleCheckboxChange(option)}
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        <label
                          htmlFor={`filter-${section.id}-${optionIdx}`}
                          className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900 cursor-pointer"
                        >
                          {section.name === "payment"
                            ? t(translateFilterItems(option.label))
                            : option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          ))}
        </Popover.Group>
      </section>
    </div>
  );
}
