import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Text } from "../common/Text";
import { useTranslation } from "react-i18next";
import { Select } from "../common/Select";

export function AccountLanguage({ backIcon }) {
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: useSelector((state) => state.language).language,
    label: "Language",
  });
  const [languages] = useState([
    { label: "francais", value: "fr" },
    { label: "Arabe", value: "ar" },
    { label: "Anglais", value: "en" },
  ]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    let x = languages.find((x) => {
      return selectedLanguage.value === x.value;
    });
    setSelectedLanguage(x);
  }, []); // eslint-disable-line

  return (
    <>
      <div className="mb-4 flex justify-between">
        <span className="rtl:transform  rtl:rotate-180 rtl:self-start">
          {backIcon}
        </span>
        <Text weight="bold">
          {t("settings.accountLanguage.accountLanguage")}
        </Text>
      </div>
      <Select
        options={languages}
        selected={selectedLanguage}
        setSelected={setSelectedLanguage}
        onOptionSelected={(value) => {
          dispatch({ type: "changeLanguage", preload: value });
        }}
      />
    </>
  );
}
