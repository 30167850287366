import axios, { isNetworkError, CLIENT_ID } from "./axios";
import originalAxios from "axios";

export const GRANT_TYPE = "password";

export const signUp = (mydata) => {
  originalAxios.defaults.headers.post["Content-Type"] = "application/json";
  originalAxios.defaults.baseURL = axios.defaults.baseURL;
  return originalAxios
    .post("/connect/signup/", mydata)
    .then((responses) => {
      return responses;
    })
    .catch((errors) => {
      if (isNetworkError(errors)) return "network error";
      return errors.response;
    });
};

// get token it's same with login  function
export const getToken = (mydata) => {
  originalAxios.defaults.headers.post["Content-Type"] = "application/json";
  originalAxios.defaults.baseURL = axios.defaults.baseURL;
  return originalAxios
    .post("/connect/o/token/", {
      grant_type: GRANT_TYPE,
      client_id: CLIENT_ID,
      username: mydata.username.toLowerCase(),
      password: mydata.password,
    })
    .then((responses) => {
      return responses;
    })
    .catch((errors) => {
      if (errors.message === "Network Error") return "network error";
      return errors.response;
    });
};

export const refreshExpiredToken = (mydata) => {
  originalAxios.defaults.headers.post["Content-Type"] = "application/json";
  originalAxios.defaults.baseURL = axios.defaults.baseURL;
  return originalAxios
    .post("/connect/o/token/", {
      grant_type: "refresh_token",
      client_id: CLIENT_ID,
      refresh_token: mydata.refresh_token,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    })
    .then((responses) => {
      return responses;
    })
    .catch((errors) => {
      if (isNetworkError(errors)) return "network error";
      return errors.response;
    });
};

export const phoneVerification = (mydata) => {
  return axios
    .post("/connect/verification/", mydata)
    .then((responses) => {
      return responses;
    })
    .catch((errors) => {
      if (isNetworkError(errors)) return "network error";
      return errors.response;
    });
};

export const getResetPasswordCode = (mydata) => {
  return axios
    .post("/connect/reset-password/phone/", mydata)
    .then((responses) => {
      return responses;
    })
    .catch((errors) => {
      if (isNetworkError(errors)) return "network error";
      return errors.response;
    });
};

export const getResetPasswordToken = (mydata) => {
  return axios
    .post("/connect/reset-password/mail/", mydata)
    .then((responses) => {
      return responses;
    })
    .catch((errors) => {
      if (isNetworkError(errors)) return "network error";
      return errors.response;
    });
};

export const confrimResetPasswordCode = (mydata) => {
  return axios
    .post("/connect/reset-password/phone/verif-code/", mydata)
    .then((responses) => {
      return responses;
    })
    .catch((errors) => {
      if (isNetworkError(errors)) return "network error";
      return errors.response;
    });
};

export const resetPassword = (mydata) => {
  return axios
    .post("/connect/reset-password/phone/confirm/", mydata)
    .then((responses) => {
      return responses;
    })
    .catch((errors) => {
      if (isNetworkError(errors)) return "network error";
      return errors.response;
    });
};

export const resetPasswordEmail = (mydata) => {
  return axios
    .post("/connect/reset-password/mail/confirm/", mydata)
    .then((responses) => {
      return responses;
    })
    .catch((errors) => {
      if (isNetworkError(errors)) return "network error";
      return errors.response;
    });
};

export const changePassword = (mydata) => {
  return axios
    .put("/connect/change-password/", mydata)
    .then((responses) => {
      return responses;
    })
    .catch((errors) => errors.response);
};

export const getUserInfo = (_) => {
  return axios
    .get("/connect/user/")
    .then((responses) => {
      return new Promise((resolve) => {
        resolve(JSON.stringify(responses.data));
      });
    })
    .catch((errors) => {
      return new Promise((resolve) => {
        resolve(errors.response);
      });
    });
};
export const updateUserInfo = (myData) => {
  return axios
    .put("/connect/user/", myData)
    .then((responses) => {
      return responses;
    })
    .catch((errors) => {
      return errors.response;
    });
};

export const checkRecaptchaToken = (mydata) => {
  return axios
    .post("/connect/recaptcha-verification/", mydata)
    .then((responses) => {
      return responses;
    })
    .catch((errors) => errors.response);
};

export const getUserDevices = () => {
  return axios
    .get("/connect/device/")
    .then((responses) => {
      return responses;
    })
    .catch((errors) => errors.response);
};
