import { getAllWishes } from "../../api";

export const getWishes = async (page, source) => {
  try {
    const { results, count } = await getAllWishes(page, source);
    return { results, count };
  } catch (e) {
    return { results: [], count: 0 };
  }
};
