import { ArrowSmLeftIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Menu,
  MyAccout,
  Page,
  Addresses,
  Security,
  AccountLanguage,
} from "../../components";

import { Subscription } from "../../components/settings/Subscription";
import { getUserInformations } from "../../utils/services";
import { useTranslation } from "react-i18next";
export default function Settings() {
  const [selectedMenu, setSelectedMenu] = useState("my-account");
  const [responsiveMenu, setResponsiveMenu] = useState(true);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const backIcon = (
    <ArrowSmLeftIcon
      className="w-6 mr-2 block sm:hidden cursor-pointer hover:opacity-50"
      onClick={() => setResponsiveMenu(true)}
    />
  );
  const menuData = [
    {
      label: t("settings.myAccount.myAccount"),
      key: "my-account",
      component: (
        <MyAccout
          user={user}
          setUser={setUser}
          setLoading={setLoading}
          backIcon={backIcon}
        />
      ),
    },
    {
      label: t("settings.myAddresses.myAddresses"),
      key: "my-addresses",
      component: <Addresses backIcon={backIcon} />,
    },
    {
      label: t("settings.security.security"),
      key: "security",
      component: <Security backIcon={backIcon} />,
    },
    {
      label: t("settings.subscription.subscription"),
      key: "subscription",
      component: <Subscription backIcon={backIcon} />,
    },
    {
      label: t("settings.accountLanguage.accountLanguage"),
      key: "accountLanguage",
      component: <AccountLanguage backIcon={backIcon} />,
    },
  ];

  useEffect(() => {
    getUserInformations(dispatch).then((user) => {
      setUser(user);
      setLoading(false);
    });
  }, [dispatch]);

  return (
    <Page title={t("settings.userSettings")} loading={loading} notification={true}>
      <div className="h-full bg-white grid grid-cols-1 sm:grid-cols-3 gap-4 rounded-md outline-none ring-2 ring-gray-200 shadow">
        <div
          className={`bg-white col-span-1 p-6 rounded-md rounded-r-none ring-2 ring-gray-200 ${
            responsiveMenu ? "block" : "hidden"
          } sm:block`}
        >
          <Menu
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            setResponsiveMenu={setResponsiveMenu}
            data={menuData}
          />
        </div>
        <div
          className={`bg-white col-span-2 p-6 rounded-md ${
            responsiveMenu ? "hidden" : "block"
          } sm:block`}
        >
          {
            menuData.filter((element) => element?.key === selectedMenu)[0]
              ?.component
          }
        </div>
      </div>
    </Page>
  );
}
