import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { XIcon, MenuIcon } from "@heroicons/react/outline";

import kio from "../../assets/images/kio.svg";
import kioPrimary from "../../assets/images/kio-primary.svg";
import { useTranslation } from "react-i18next";
import FlaggedSelect from "../common/FlaggedSelect";

export function Header({ elements }) {
  const location = useLocation();
  const history = useHistory();
  const [selected, setSelected] = useState(location.pathname);
  const { t } = useTranslation();

  return (
    <Popover
      className="z-30 relative w-screen box-border"
      style={{
        backgroundColor: "#0066ff",
        maxHeight: "4rem",
      }}
    >
      {({ open, close }) => (
        <>
          <div className="z-30 w-screen px-4 sm:px-6 h-16 flex justify-center items-center">
            <div className="flex flex-1 justify-between items-center md:space-x-10">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <Link
                  to="/"
                  onClick={() => {
                    setTimeout(() => {
                      close();
                    }, 200);
                    setSelected("/");
                  }}
                >
                  <span className="sr-only">{t("layout.kioLogo")}</span>
                  <img
                    src={kio}
                    alt="kio's logo"
                    className="h-8 w-10 sm:h-10"
                  />
                </Link>
              </div>

              <div className="-my-2">
                <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center bg-gray-100  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-100">
                  <span className="sr-only">{t("layout.openMenu")} </span>
                  <MenuIcon className="h-6 w-6" />
                </Popover.Button>
              </div>
            </div>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-30 bg-gray-50"
            >
              <div className="p-5">
                <div className="flex gap-1 items-center justify-between px-4 sm:px-6">
                  <Link
                    to="/"
                    onClick={() => {
                      setTimeout(() => {
                        close();
                      }, 200);
                      setSelected("/");
                    }}
                  >
                    <span className="sr-only">{t("layout.kioLogo")}</span>
                    <img
                      src={kioPrimary}
                      alt="kio's logo"
                      className="h-8 w-10 sm:h-10"
                    />
                  </Link>
                  <div className="ltr:ml-auto rtl:mr-auto">
                    <FlaggedSelect></FlaggedSelect>
                  </div>
                  <div>
                    <Popover.Button className="bg-gray-100 rounded-md p-2 inline-flex items-center justify-center text-indigo-800 hover:text-indigo-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600">
                      <span className="sr-only">{t("layout.closeMenu")}</span>
                      <XIcon className="h-6 w-6" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8 ">
                    {elements.map((navigation, index) => {
                      return (
                        <ul
                          className={`${navigation.primary ? "flex-1" : ""}`}
                          key={index}
                        >
                          {navigation.children.map(
                            ({ key, onClick, icon, title }) => {
                              return (
                                <div
                                  key={key}
                                  className={`h-14 flex items-center rounded-3xl px-4 sm:px-6 cursor-pointer transition-colors duration-200 text-gray-600 hover:text-gray-100 hover:bg-kio-500 hover:bg-opacity-95 ${
                                    selected === key ? "text-kio-500" : ""
                                  }`}
                                  onClick={() => {
                                    setSelected(key);
                                    history.push(key);
                                    setTimeout(() => {
                                      if (onClick) {
                                        onClick();
                                        return;
                                      }
                                      close();
                                    }, 200);
                                  }}
                                >
                                  {icon}
                                  <span className="mx-4 text-lg font-normal">
                                    {title}
                                  </span>
                                  <span className="flex-grow"></span>
                                </div>
                              );
                            }
                          )}
                        </ul>
                      );
                    })}
                    {/* <a
                        href="#"
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        Télécharger
                      </a>

                      <a
                        href="#"
                        className="text-base font-medium text-gray-900 hover:text-gray-700"
                      >
                        Assistance
                      </a>
                     */}
                  </nav>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
