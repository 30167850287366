import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import kioPrimary from "../../assets/images/kio-primary.svg";
import { useTranslation } from "react-i18next";

export const TabletSidebar = ({ elements }) => {
  const history = useHistory();
  const [selected, setSelected] = useState("");
  const { t } = useTranslation();

  return (
    <div className="relative h-full max-h-full border-r border-gray-300 flex flex-col items-center overflow-y-auto">
      <div className=" h-16 flex items-center">
        <Link
          to="/"
          onClick={() => {
            setSelected("/");
          }}
        >
          <span className="sr-only"> {t("layout.kioLogo")}</span>
          <img src={kioPrimary} alt="kio's logo" className="h-8 w-10 sm:h-10" />
        </Link>
      </div>
      <div className="w-24 flex-1 py-2">
        <nav className="w-full h-full flex flex-col items-center">
          {elements.map((navigation, index) => {
            return (
              <ul
                className={`${navigation.primary ? "flex-1" : ""}`}
                key={index}
              >
                {navigation.children.map(({ key, onClick, icon }) => {
                  return (
                    <div
                      className={` h-14 flex items-center rounded-3xl px-4  cursor-pointer transition-colors duration-200 text-gray-600 hover:text-gray-100 hover:bg-kio-500 hover:bg-opacity-95 ${
                        selected === key ? "text-kio-500" : " "
                      }`}
                      onClick={() => {
                        if (onClick) {
                          onClick();
                          return;
                        }
                        setSelected(key);
                        history.push(key);
                      }}
                      key={key}
                    >
                      {icon}
                    </div>
                  );
                })}
              </ul>
            );
          })}
        </nav>
      </div>
    </div>
  );
};
