const initialState = { stores: [] };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "saveStores":
      const { stores } = action.payload;
      return {
        stores,
      };
    default:
      return state;
  }
}
