import { Switch, Route } from "react-router-dom";
import { Orders, OrderDetails } from "../pages/orders";
import { ErrorPage } from "../components";

export default function OrderNavigation() {
  let routes = (
    <Switch>
      <Route exact path={`/orders/all`} render={() => <Orders />} />
      <Route
        path="/orders/:id"
        component={({ match }) => <OrderDetails id={match.params.id} />}
      />
      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  );

  return routes;
}
