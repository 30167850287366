import "../../assets/css/loader.scss";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/loading";
function Loading({ fullScreen = true, bg = "gray-50" }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      className={`${
        fullScreen ? "w-screen h-screen" : "w-full h-full"
      } flex justify-center items-center bg-${bg}`}
    >
      <Lottie
        options={defaultOptions}
        height={200}
        width={200}
   
      />
    </div>
  );
}

export default Loading;
