import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { PencilIcon, UserIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dropper,
  Form,
  InputText,
  Modal,
  Page,
  Text,
  Banner,
} from "../../components";
import {
  getUserInformations,
  updateUserInformations,
  uploadFile,
} from "../../utils/services";

export default function ProfileDetails({ username }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });
  const { t } = useTranslation();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    getUserInformations(dispatch)
      .then((user) => {
        setUser(user);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [dispatch]);

  const onSubmit = async (data) => {
    try {
      setInnerLoading(true);
      if (files.length > 0) {
        if (files?.[0]?.size <= 5000000) {
          const { file: avatar_src } = await uploadFile(files[0]);
          if (avatar_src) {
            data.avatar_src = avatar_src.includes("localstack")
              ? avatar_src.replace("localstack", "192.168.1.21")
              : avatar_src;
          }
        } else {
          throw Error(t("errorMessage.sizeError"));
        }
      }

      updateUserInformations(dispatch, data, (x) => {
        return t(x);
      }).then((user) => {
        setUser(user);
        setInnerLoading(false);
        setEditOpen(false);
      });
    } catch (err) {
      setError(err.message);
      setInnerLoading(false);
    }
    setError("");
    setFiles([]);
  };

  return (
    <>
      <Page
        title={user?.username ?? username}
        loading={loading}
        notification={true}
      >
        <hr />

        <div className="flex flex-col items-center text-center">
          <div className="h-32 w-32 my-4 relative flex flex-col items-center">
            <div className="h-32 w-32 rounded-full flex items-center justify-center">
              {user?.avatar_src ? (
                <img
                  src={user?.avatar_src}
                  alt={user?.full_name}
                  className="h-full w-full rounded-full flex items-center justify-center object-cover"
                />
              ) : (
                <div className="h-32 w-32 rounded-full flex items-center justify-center bg-gray-100">
                  <UserIcon className="h-8 w-8 text-gray-600" />
                </div>
              )}
            </div>
            <div className="absolute -bottom-3 h-10 w-10 bg-gray-100 rounded-full shadow flex items-center justify-center">
              <PencilIcon
                className="h-6 w-6 text-kio-500 cursor-pointer"
                onClick={(_) => {
                  setEditOpen(true);
                }}
              />
            </div>
          </div>

          <Text size="xl4" uppercase={true}>
            {user.full_name}
          </Text>
          <span className="my-2">
            <Text size="base" color="text-gray-500">
              {user?.username}
            </Text>
          </span>
          <span className="my-2">
            <Text size="lg" color="text-gray-700">
              /
            </Text>
          </span>

          <span className="">
            <Text size="base" color="text-gray-700">
              <span> {t("profil.joined")}</span>
              <p className="rtl:ltr-text">
                {new Intl.DateTimeFormat("fr-FR", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                }).format(Date.parse(new Date()))}
              </p>
            </Text>
          </span>
        </div>
      </Page>
      <Modal
        isOpen={editOpen}
        setIsOpen={setEditOpen}
        title={t("profil.modifyProfil")}
        disabled={loading || innerLoading}
        onClose={() => {
          setError("");
          setFiles([]);
          reset();
        }}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Section>
            {error && <Banner message={error} />}
            <InputText
              label={t("auth.name")}
              className="rtl:text-right"
              error={
                errors?.last_name
                  ? errors?.last_name?.message || t("auth.required")
                  : ""
              }
              name="last_name"
              register={register}
              pattern={/^[A-Za-z]+$/i}
              required={true}
              defaultValue={user?.last_name}
            />
            <InputText
              label={t("auth.lastName")}
              className="rtl:text-right"
              error={errors?.first_name ? t("auth.required") : ""}
              name="first_name"
              register={register}
              pattern={/^[A-Za-z]+$/i}
              required={true}
              defaultValue={user?.first_name}
            />
            <Dropper
              title={t("profil.profilPicture")}
              className="rtl:text-right"
              caption={t("profil.pngJpg")}
              files={files}
              setFiles={setFiles}
              multiple={false}
            />

            <img
              alt=" "
              className="h-[95%] w-full rounded-[80px] flex items-center justify-center bg-gray-100"
              src={files?.length > 0 ? files[File] : user?.avatar_src}
            />

            <div className="mt-4">
              <div className="w-full flex justify-end">
                <Button
                  fullWidth={false}
                  disabled={
                    loading || innerLoading || Object.keys(errors).length !== 0
                  }
                  loading={loading || innerLoading}
                >
                  {t("commons.modify")}
                </Button>
              </div>
            </div>
          </Modal.Section>
        </Form>
      </Modal>
    </>
  );
}
