import {
  getAllAddresses,
  deleteAddresseById,
  createAddress,
  updateAddress,
  getCountries,
} from "../../api/";
import { getCities, getStates } from "../../api/addresses.instance";
export const getAddresses = async (source) => {
  try {
    const response = await getAllAddresses(source);
    return response;
  } catch (e) {
    return [];
  }
};
export const addAddress = async (data) => {
  try {
    await createAddress(data);
  } catch (err) {
    throw err;
  }
};
export const editAddress = async (data) => {
  try {
    await updateAddress(data);
  } catch (err) {
    throw err;
  }
};
export const deleteAddresse = async (id) => {
  try {
    const response = await deleteAddresseById(id);
    return response;
  } catch (e) {
    return [];
  }
};
export const generateAddresse = (addresse) => {
  var finalAddresse = "";
  if (addresse?.name) finalAddresse += addresse?.name + " : ";
  if (addresse?.street) finalAddresse += addresse?.street;
  if (addresse?.street2) finalAddresse += ", " + addresse?.street2;
  return finalAddresse;
};

export const getCountriesStatesCities = async (source) => {
  try {
    const countriesResponse = await getCountries(source);
    const statesResponse = await getStates(countriesResponse[0]?.id, source);
    const citiesResponse = await getCities(
      countriesResponse[0]?.id,
      statesResponse[0]?.id,
      source
    );
    let countries = countriesResponse?.map((country) =>
      Object({ label: country?.country_name, value: country?.id })
    );
    let states = statesResponse?.map((state) =>
      Object({ label: state?.name, value: state?.id })
    );
    let cities = citiesResponse?.map((city) =>
      Object({ label: city?.name, value: city?.id })
    );
    return { countries, states, cities };
  } catch (err) {
    throw err;
  }
};

export const onCountryChange = async (country, source) => {
  try {
    const statesResponse = await getStates(country, source);
    const citiesResponse = await getCities(
      country,
      statesResponse[0]?.id,
      source
    );
    let states = statesResponse?.map((state) =>
      Object({ label: state?.name, value: state?.id })
    );
    let cities = citiesResponse?.map((city) =>
      Object({ label: city?.name, value: city?.id })
    );
    return { states, cities };
  } catch (err) {
    throw err;
  }
};

export const onStateChange = async (country, state, source) => {
  try {
    const citiesResponse = await getCities(country, state, source);
    let cities = citiesResponse?.map((city) =>
      Object({ label: city?.name, value: city?.id })
    );
    return { cities };
  } catch (err) {
    throw err;
  }
};
