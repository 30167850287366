import { getAllOrders, getOrderById } from "../../api";
export const getOrders = async (page, source) => {
  try {
    const response = await getAllOrders(page, source);
    return response;
  } catch (e) {
    console.log(e);
    return [];
  }
};
export const getOrder = async (id) => {
  try {
    const response = await getOrderById(id);
    return response;
  } catch (e) {
    return [];
  }
};
