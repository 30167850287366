import { Table, Page, ErrorPage } from "../../components";
import { useState, useEffect } from "react";
import { getOrder } from "../../utils/services";
import { useTranslation } from "react-i18next";

export default function OrderDetails({ id }) {
  const [orderItems, setOrderItems] = useState(null);
  const [order, setOrder] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getOrder(id).then((res) => {
      if (res?.status === 404 || res === "network error") {
        setError(true);
      }
      setOrder(res.order);
      setOrderItems(res.order_items);

      if (res.order_addresses) {
        setShippingAddress(
          res.order_addresses.find(({ purpose }) => purpose === "shipping")
        );
        setBillingAddress(
          res.order_addresses.find(({ purpose }) => purpose === "billing")
        );
      }
      setLoading(false);
    });
  }, [id]);
  const columns = [
    {
      title: `${t("orders.image")}`,
      dataIndex: "src",
      render: ({ variant }) => (
        <img alt="produit" width={150} src={variant?.src} />
      ),
    },
    { title: `${t("orders.name")}`, dataIndex: "name" },
    { title: `${t("orders.quantity")}`, dataIndex: "quantity" },
    {
      title: `${t("orders.price")}`,
      render: ({ price, price_currency }) => (
        <>
          {price} {price_currency}
        </>
      ),
    },
  ];
  const generateBadge = (payment) => {
    switch (payment) {
      case "PAID":
        return { text: `${t("orders.paid")}`, status: "success" };
      case "PENDING":
        return { text: `${t("orders.onHold")}`, status: "warning" };
      case "PARTIALLY_PAID":
        return { text: `${t("orders.partiallyPaid")}`, status: "warning" };
      default:
        break;
    }
  };
  return (
    <>
      {!error ? (
        <Page
          title={`${t("orders.orderNum")} ${!loading ? order?.sequence : ""}`}
          badge={!loading && generateBadge(order?.payment)}
          subtitle={t("orders.detailsOrder")}
          loading={loading}
          breadcrumbs={true}
        >
          <div className="text-2xl pb-4"> {t("orders.yourProducts")} </div>
          <Table dataSource={orderItems ?? []} columns={columns} />
          <div className="text-2xl py-4"> {t("orders.recapOrder")} </div>
          <div className="flex flex-row w-full mb-4 justify-between">
            <div className="px-2 sm:px-4   bg-white rounded-lg outline-none shadow ring-2 ring-gray-200 w-full max-w-full lg:max-w-full text-center ">
              <div className="text-left text-xl pt-4 rtl:text-right">
                {t("orders.payment")}
              </div>

              <div className="py-4 flex flex-row justify-between">
                <div> {t("orders.subTotal")} : </div>
                <div className="rtl:ltr-text">
                  {order?.net_total} {order?.net_total_currency}
                </div>
              </div>

              <hr />

              <div className="py-4 flex flex-row justify-between">
                <div> {t("orders.delivery")} : </div>
                <div className="rtl:ltr-text">
                  {order?.shipping_fees} {order?.shipping_fees_currency}
                </div>
              </div>

              <hr />
              <div className="py-4 flex flex-row justify-between">
                <div> {t("orders.ttcTotal")} : </div>
                <div className="rtl:ltr-text">
                  {order?.gross_total} {order?.gross_total_currency}
                </div>
              </div>
              <hr />
              <div className="py-4 flex flex-row justify-between">
                <div> {t("orders.paidd")} : </div>
                <div className="rtl:ltr-text">
                  {order?.paid} {order?.paid_currency}
                </div>
              </div>
            </div>
          </div>
          {(shippingAddress || billingAddress) && (
            <div className="flex flex-row w-full my-4 justify-between">
              <div className="px-2 sm:px-4   bg-white rounded-lg outline-none shadow ring-2 ring-gray-200 w-full max-w-full lg:max-w-full text-center ">
                <div className="text-left text-xl pt-4">
                  {t("orders.adresses")}
                </div>

                {shippingAddress && (
                  <div className="py-4 flex flex-row justify-between">
                    <div> {t("orders.deliveryAdress")} : </div>
                    <div>
                      {shippingAddress?.street1 &&
                        shippingAddress?.street1 + ","}
                      {shippingAddress?.street2 &&
                        shippingAddress?.street2 + ","}
                      {shippingAddress?.city}, {shippingAddress?.state} ,
                      {shippingAddress?.country}.
                    </div>
                  </div>
                )}
                {billingAddress && (
                  <>
                    <hr />
                    <div className="py-4 flex flex-row justify-between">
                      <div> {t("orders.billingAdress")} : </div>
                      <div>
                        {billingAddress?.street1 &&
                          billingAddress?.street1 + ","}
                        {billingAddress?.street2 &&
                          billingAddress?.street2 + ","}
                        {billingAddress?.city}, {billingAddress?.state} ,
                        {billingAddress?.country}.
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {order?.customer_note && (
            <div className="flex flex-row w-full my-4 justify-between">
              <div className="px-2 sm:px-4   bg-white rounded-lg outline-none shadow ring-2 ring-gray-200 w-full max-w-full lg:max-w-full text-center ">
                <div className="text-left text-xl pt-4">
                  {t("orders.notice")}
                </div>

                <div className="py-4 text-left"> {order?.customer_note}</div>
              </div>
            </div>
          )}
        </Page>
      ) : (
        <ErrorPage />
      )}
    </>
  );
}
