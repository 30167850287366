import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Text } from "../common/Text";
import { Button } from "../common/Button";
import { Space } from "../common/Space";
import { CodeInput } from "../common/CodeInput";
import { Banner } from "../common/Banner";
import { confirmPhone, logout, resendCode } from "../../utils/services";
import { useTranslation } from "react-i18next";
import { getValueToConfirm } from "../utils";

export function StepThree({
  method,
  setConfirmationStep,
  setShowTimer,
  toConfirm,
  showTimer = false,
}) {
  const start = 30;
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [code, setCode] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState({
    minutes:
      parseInt(start / 60) < 10
        ? "0" + parseInt(start / 60)
        : parseInt(start / 60) + "",
    seconds: start % 60 < 10 ? "0" + (start % 60) : (start % 60) + "",
  });
  const { t } = useTranslation();
  const redirectTo = window.localStorage.getItem("redirectTo") ?? null;
  const onConfirmPhone = async () => {
    try {
      if (code.join("").length === 6) {
        let data = {
          email: method === 0 ? getValueToConfirm("email", user) : null,
          phone_number: method === 0 ? null : getValueToConfirm("phone", user),
          confirmation_code_email: method === 0 ? code.join("") : null,
          confirmation_code_phone: method === 0 ? null : code.join(""),
          resend: false,
        };
        setLoading(true);
        await confirmPhone(data, user, dispatch, (x) => {
          return t(x);
        });
        if (toConfirm) history.push("/settings");
        if (redirectTo) {
          window.location.href = redirectTo;
        } else {
          history.push("/");
        }
      } else {
        setError(t("errorMessage.codeIncomplet"));
      }
    } catch (error) {
      console.log(error);
      setError(error?.message);
      setLoading(false);
    }
  };

  const onResendCode = async () => {
    let data = {
      email: method === 0 ? getValueToConfirm("email", user) : null,
      phone_number: method === 0 ? null : getValueToConfirm("phone", user),
      confirmation_code_email: null,
      confirmation_code_phone: null,
      resend: true,
    };
    try {
      await resendCode(data, (x) => {
        return t(x);
      });
    } catch (err) {
      setError(err?.message);
    }
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("redirectTo");
    };
  }, []);

  useEffect(() => {
    if (!showTimer) return;
    var initialTimer = start;
    var newSeconds;
    var newMinutes;
    const counterInterval = setInterval(() => {
      initialTimer -= 1;
      newSeconds = initialTimer % 60;
      newMinutes = parseInt(initialTimer / 60);
      setTimer({
        minutes: newMinutes < 10 ? "0" + newMinutes : newMinutes + "",
        seconds: newSeconds < 10 ? "0" + newSeconds : newSeconds + "",
      });

      if (initialTimer === 0) {
        clearInterval(counterInterval);
        //
        setShowTimer(false);
        setTimer({
          minutes:
            parseInt(start / 60) < 10
              ? "0" + parseInt(start / 60)
              : parseInt(start / 60) + "",
          seconds: start % 60 < 10 ? "0" + (start % 60) : (start % 60) + "",
        });
      }
    }, 1000);
  }, [showTimer]);

  return (
    <div>
      <Text size="sm" color="text-gray-400">
        {t("auth.sendCode")}{" "}
        {method === 0 ? (
          getValueToConfirm("email", user)
        ) : (
          <p className={"rtl:ltr-text rtl:text-right inline-block"}>
            {getValueToConfirm("phone", user)}
          </p>
        )}
      </Text>
      <Space vertical="2">
        {error && (
          <Banner type="error" title={t("commons.error")} message={error} />
        )}
      </Space>
      <CodeInput setCode={setCode} code={code} onChange={() => setError("")} />
      <div className="flex flex-col sm:flex-row">
        <div className="flex flex-auto items-center">
          {showTimer ? (
            <Text size="base" color="text-gray-500">
              {t("auth.resendCodeIn")}
              <span className="text-kio-500 font-bold cursor-pointer hover:text-indigo-700">
                {timer?.minutes[0] +
                  "" +
                  timer?.minutes[1] +
                  ":" +
                  timer?.seconds[0] +
                  "" +
                  timer?.seconds[1]}
              </span>
            </Text>
          ) : (
            <Text size="sm" color="text-gray-400">
              {t("auth.codeNotReceive")}
              <span
                onClick={() => {
                  onResendCode();
                  setShowTimer(true);
                }}
                className="text-kio-500 font-bold cursor-pointer hover:text-indigo-700"
              >
                {t("auth.resend")}
              </span>
            </Text>
          )}
        </div>
        <div className="flex flex-auto py-4 sm:py-0 justify-end">
          {!toConfirm && (
            <Button
              fullWidth={false}
              responsiveFull={true}
              type="secondary"
              space
              onClick={() => logout(history, dispatch)}
            >
              {t("auth.deconnect")}
            </Button>
          )}
          <Button
            fullWidth={false}
            responsiveFull={true}
            onClick={onConfirmPhone}
            space
            loading={loading}
          >
            {t("auth.verify")}
          </Button>
        </div>
      </div>
    </div>
  );
}
