import { Text } from "../common/Text";
import { useTranslation } from "react-i18next";
import {
  DocumentTextIcon,
  PaperAirplaneIcon,
  MailIcon,
} from "@heroicons/react/outline";

function Details({ store }) {
  const { t } = useTranslation();
  return (
    <div className="m-4 p-4">
      <div className="mb-8">
        <Text weight="bold" color="text-gray-900" size="xl2">
          {store.name}
        </Text>
      </div>

      <div className=" flex items-center mt-4 text-gray-900 font-semibold xl">
        <DocumentTextIcon className="h-4 w-4 text-gray-900 mr-2 font-bold" />
        {t("explore.details")}
      </div>

      <Text size="lg" color="text-gray-700">
        {store.description}
      </Text>
      <Text size="lg" color="text-gray-700">
        {store.industry}
      </Text>

      <div className=" flex items-center mt-4 text-gray-900 font-semibold xl">
        <MailIcon className="h-4 w-4 text-gray-900 mr-2 font-bold" />
        {t("explore.contact")}
      </div>

      <Text size="lg" color="text-gray-700">
        {store.contact_email}
      </Text>
      <Text size="lg" color="text-gray-700">
        {store.website}
      </Text>

      <div className="flex items-center mt-4 text-gray-900 font-semibold xl">
        <PaperAirplaneIcon className="h-4 w-4 text-gray-900 mr-2 font-bold" />
        {t("explore.location")}
      </div>

      <Text size="lg" color="text-gray-700">
        {store.city}
      </Text>
      <Text size="lg" color="text-gray-700">
        {store.country}
      </Text>
    </div>
  );
}

export default Details;
