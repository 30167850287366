import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux"; // importing the wrapper component that allows us to pass the created store
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux";
import fr from "./languages/fr.json";
import ar from "./languages/ar.json";
import en from "./languages/en.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    fr: fr,
    ar: ar,
    en: en,
  },
  lng: "fr",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// ReactGA.initialize("UA-161774612-1", [options]);
// const pageViewsTracking = (ptops) => {
//   const pathname = props.match.pathname;
//   let pageView;
//   if (pathname === "/") pageView = "/not-found";
//   else pageView = pathname;
//   React.ReactGA.pageView(pageView);
// };

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
