import React, { useState, useEffect } from "react";
import {
  Text,
  Form,
  InputText,
  Space,
  Button,
  InputGroup,
  InputPhone,
  Banner,
} from "../../components/";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { signup, verifyRecaptchaToken } from "../../utils/services/";
import { getQueryParams } from "../../utils";

import { useDispatch, useSelector } from "react-redux";

import Lottie from "react-lottie";
import animationData from "../../assets/lotties/landing";
import { useTranslation } from "react-i18next";

export default function Signup() {
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
    getValues,
  } = useForm({ mode: "onChange" }); //form validation
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  // GET LANGUAGE TO CHANGE TEXT-DIRECTION
  const { language } = useSelector((state) => state.language);

  const [phoneTrue, setPhoneTrue] = useState(false);

  const [loading, setLoading] = useState(false);
  // URL TO REDIRECT IF EXIST
  const fromUrl = getQueryParams();

  //recaptcha initialisation
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`,
      function () {}
    );

    return () => {
      const script = document.getElementById("recaptcha-script");
      if (script) {
        script.remove();
      }
      const recaptchaElems =
        document.getElementsByClassName("grecaptcha-badge");
      if (recaptchaElems.length) {
        recaptchaElems[0].remove();
      }
    };
  }, []);

  const onSubmit = async (data) => {
    if (phoneTrue) {
      data.email = null;
      data["phone_number"] = "+" + data.phone_number;
    } else data["phone_number"] = null;
    try {
      setLoading(true);
      await verifyRecaptchaToken((x) => {
        return t(x);
      });

      await signup(data, dispatch, (x) => {
        return t(x);
      });
      setLoading(false);
      if (fromUrl.redirectTo)
        window.localStorage.setItem("redirectTo", fromUrl.redirectTo);
      history.push("/");
    } catch (err) {
      let errMessage = JSON.parse(err?.message);

      setError(errMessage?.field, {
        type: "manual",
        message: errMessage?.msg,
      });
      setLoading(false);
    }
  }; //sign up user

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-1 h-full">
      <div className="bg-kio-500 hidden hidden rtl:order-2 h-full w-full  flex-col items-center justify-center">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
      <div
        className={`flex items-center justify-center justify-self-center w-full  md:w-3/5 ${
          language === "ar" && "text-right"
        }`}
      >
        <div className="flex flex-col w-full xl:w-9/12 px-16 py-4">
          <Text size="xl2" weight="bold">
            {t("auth.createAccount")}
          </Text>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Space top="2">
              {(errors?.connection || errors?.recaptcha) && (
                <Banner
                  title="Erreur"
                  message={
                    errors?.connection?.message ?? errors?.recaptcha?.message
                  }
                />
              )}
            </Space>
            <InputText
              label={t("auth.username")}
              name="username"
              register={register}
              required
              pattern={{
                value: /^[A-Za-z\u0600-\u06FF][A-Za-z0-9_\u0600-\u06FF]{4,20}$/,
                message: t("errorMessage.errorUsername"),
              }}
              error={
                errors?.username
                  ? ["manual", "pattern"].includes(errors?.username?.type)
                    ? errors?.username?.message
                    : t("auth.required")
                  : ""
              }
            />
            <InputGroup>
              <Space right="1">
                <InputText
                  label={t("auth.name")}
                  name="last_name"
                  register={register}
                  error={
                    errors?.last_name
                      ? errors?.last_name?.message
                        ? errors?.last_name?.message
                        : t("auth.required")
                      : ""
                  }
                  required
                />
              </Space>

              <Space left="1">
                <InputText
                  label={t("auth.lastName")}
                  name="first_name"
                  register={register}
                  error={
                    errors?.first_name
                      ? errors?.first_name?.message
                        ? errors?.first_name?.message
                        : t("auth.required")
                      : ""
                  }
                  required
                />
              </Space>
            </InputGroup>
            {phoneTrue ? (
              <div>
                <InputPhone
                  label={t("auth.phoneNumber")}
                  control={control}
                  required
                  error={
                    errors?.phone_number
                      ? ["manual", "pattern"].includes(
                          errors?.phone_number?.type
                        )
                        ? errors?.phone_number?.message
                        : t("auth.required")
                      : ""
                  }
                  register={register}
                />
                <span
                  className="text-kio-500 text-sm cursor-pointer flex justify-end hover:text-black hover:underline"
                  onClick={() => {
                    setPhoneTrue(false);
                  }}
                >
                  {t("auth.byMail")}
                </span>
              </div>
            ) : (
              <div>
                <InputText
                  label={t("auth.email")}
                  type="email"
                  name="email"
                  register={register}
                  required
                  pattern={{
                    value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                    message: t("errorMessage.invalidValue"),
                  }}
                  error={
                    errors?.email
                      ? ["manual", "pattern"].includes(errors?.email?.type)
                        ? errors?.email?.message
                        : t("auth.required")
                      : ""
                  }
                />
                <span
                  className="text-kio-500 text-sm cursor-pointer flex justify-end hover:text-black hover:underline"
                  onClick={() => {
                    setPhoneTrue(true);
                  }}
                >
                  {t("auth.byPhone")}
                </span>
              </div>
            )}

            <InputText
              label={t("auth.password")}
              register={register}
              name="password"
              required
              type="password"
              minLength={8}
              maxLength={150}
              pattern={{
                value:
                  /^(?=.*[A-Z])(?=.*[()[\]{}|\\~!@#$%^&*_\-+=;:,<>./?])(?=.*\d).*$/,
                message: t("auth.strongPassword"),
              }}
              error={
                errors?.password
                  ? ["manual", "pattern"].includes(errors?.password?.type)
                    ? errors?.password?.message
                    : errors?.password?.type === "minLength"
                    ? t("errorMessage.errorPassword")
                    : errors?.password?.type === "maxLength"
                    ? t("errorMessage.maxLenght")
                    : t("auth.required")
                  : ""
              }
            />
            <InputText
              label={t("auth.confirmPassword")}
              register={register}
              name="confirm_password"
              required
              type="password"
              validate={(value) =>
                value === getValues("password") ||
                t("errorMessage.differentPassword")
              }
              error={
                errors?.confirm_password
                  ? ["manual", "validate"].includes(
                      errors?.confirm_password?.type
                    )
                    ? errors?.confirm_password?.message
                    : t("auth.required")
                  : ""
              }
            />
            <Space top="4">
              <Button disabled={loading} loading={loading}>
                {t("auth.register")}
              </Button>
            </Space>

            <Space vertical="2">
              <Text size="sm" color="text-gray-400">
                {t("auth.alreadyMember")}
                <span
                  className="hover:underline text-kio-500 cursor-pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push("/");
                  }}
                >
                  {t("auth.login")}
                </span>
              </Text>
            </Space>
          </Form>
        </div>
      </div>
    </div>
  );
}
