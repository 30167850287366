import React from "react";

/**
 * Button
 * @description
 * @param {object} props Button props.
 * @param {string} props.action Button type of button submit - ...
 * @param {string} props.type Button style type: primary - secondary - green
 * @param {boolean} props.disabled button disabled.
 * @param {JSX.Element} props.children elements inside the components.
 * @param {boolean} props.loading button loading
 * @param {boolean} props.fullWidth button with fulll
 * @param {boolean} props.responsiveFull button fullwidth in responsive
 * @param {boolean} props.space space arround the button
 * @param {Function} props.onClick handle click event
 * @returns JSX.Element
 * @author
 */
export function Button({
  children,
  action = "submit",
  type = "primary",
  size = "medium",
  disabled = false,
  loading = false,
  fullWidth = true,
  responsiveFull = false,
  space = false,
  PaddingX = "px-4",
  onClick = () => {},
  ...props
}) {
  return (
    <button
      type={action}
      disabled={disabled}
      onClick={onClick}
      className={`relative ${space ? "m-1" : ""} ${
        fullWidth ? "w-full" : PaddingX
      } ${
        responsiveFull
          ? fullWidth
            ? "w-full sm:flex-1"
            : "w-full flex-1 sm:flex-none sm:w-max"
          : ""
      } rounded-md outline-none ring-green-200 focus:ring-2  ${
        BUTTON_SIZE[size]
      } ${Button_TYPE[type]}  ${
        disabled ? "cursor-not-allowed opacity-30" : "cursor-pointer"
      }`}
    >
      {loading && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black opacity-75 rounded-md">
          <svg
            className="animate-spin h-5 w-5 mr-3 text-white"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-75"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-85"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      )}
      {children}
    </button>
  );
}

const BUTTON_SIZE = {
  small: "py-2",
  medium: "py-4",
};

const Button_TYPE = {
  primary: "text-white bg-kio-500 hover:bg-indigo-600",
  primary_text: "text-kio-500 hover:text-indigo-600",
  secondary: "color-kio-500 bg-gray-50 hover:bg-gray-100",
  update: "text-white bg-green-500 hover:bg-green-600",
  update_text:
    " text-green-500 hover:text-green-600 focus:ring-transparent focus:ring-0",
  delete: "text-white bg-red-500 hover:bg-red-600",
  delete_text:
    "text-red-500 hover:text-red-600 focus:ring-transparent focus:ring-0",
};
