export const isObjectEmpty = (objectName) => {
  return objectName && !Object.values(objectName).includes(true);
};

export const translateFilterItems = (payment) => {
  switch (payment) {
    case "PAID":
      return "orders.paid";
    case "PENDING":
      return "orders.onHold";
    case "PARTIALLY_PAID":
      return "orders.partiallyPaid";
    default:
      break;
  }
};

/**
 *  get the curret field to confirm 'email | phone'
 * @param {string} toConfirm - field
 * @param {object} user -  user object from redux state
 * @returns {string} - value to confirm
 */

export function getValueToConfirm(toConfirm, user) {
  if (toConfirm === "email" && user.confirmation?.email_confirmed === false) {
    return user.confirmation?.last_email;
  } else if (
    toConfirm === "phone" &&
    user.confirmation?.phone_confirmed === false
  ) {
    return user?.confirmation?.last_phone_number;
  }
}

export function formatSelectData(field) {
  return Object({ label: field?.name, value: field?.id });
}
