import { useMemo, useState } from "react";
import { useSortableTable } from "../../hooks/useSortableTable";
import { useTranslation } from "react-i18next";

import FilterIcon from "./FilterIcon";
import sortUp from "../../assets/images/sortUp.svg";
import sortDown from "../../assets/images/sortDown.svg";
export default function Table({ dataSource, columns, innerLoading, onClick }) {
  const { t } = useTranslation();
  const [tableData, handleSorting, setFilters] = useSortableTable(dataSource);
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const handleSortingChange = (dataindex) => {
    const sortOrder =
      dataindex === sortField && order === ""
        ? "asc"
        : order === "asc"
        ? "desc"
        : "";

    setSortField(dataindex);
    setOrder(sortOrder);
    handleSorting(dataindex, sortOrder);
  };
  return (
    <>
      <TableContainer innerLoading={innerLoading}>
        <THead>
          <TRow>
            {columns.map((column, index) => (
              <THeadCell
                key={index}
                {...column}
                index={index}
                handleSortingChange={handleSortingChange}
                setFilters={setFilters}
                dataSource={dataSource}
                order={order}
              ></THeadCell>
            ))}
          </TRow>
        </THead>
        <TBody>
          {tableData.length === 0 ? (
            <>
              <TRow className={"hidden"}></TRow>
              <TRow>
                <TCell colSpan={5} className={"h-64 text-center"}>
                  <p>{t("settings.subscription.dataNotFound")}</p>
                </TCell>
              </TRow>
            </>
          ) : (
            tableData.map((item, index) => (
              <TRow
                className={
                  "hover:bg-gray-100 cursor-pointer" +
                  (index % 2 !== 0 ? " bg-gray-50" : "")
                }
                key={item.id}
              >
                {columns.map((column, index) => {
                  return (
                    <TCell
                      className="px-6 py-4 whitespace-nowrap rtl:ltr-text rtl:text-right"
                      key={`column ${index}`}
                      onClick={() => {
                        if (onClick) {
                          onClick(item);
                          return;
                        }
                      }}
                    >
                      {column.render
                        ? column.render(item)
                        : item[column.dataIndex]}
                    </TCell>
                  );
                })}
              </TRow>
            ))
          )}
        </TBody>
      </TableContainer>
    </>
  );
}

function TableContainer({ children, innerLoading }) {
  return (
    <div className={"flex relative flex-col " + (innerLoading && "opacity-50")}>
      {innerLoading && <div className="z-2 h-full w-full absolute"></div>}
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block w-full sm:px-6 lg:px-8">
          <div className="ring-2 ring-gray-200 overflow-x-scroll border-b border-gray-200 sm:rounded-md">
            <table className="min-w-full divide-y divide-gray-200">
              {children}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function TRow({ children, className, ...other }) {
  return (
    <tr className={className} {...other}>
      {children}
    </tr>
  );
}
function THeadCell({
  title,
  dataIndex,
  sortable,
  filter,
  index,
  order,
  handleSortingChange,
  setFilters,
  dataSource,
  children,
}) {
  const filterData = useMemo(
    () => Array(...new Set(dataSource.map((item) => item[dataIndex]))),
    [dataSource]
  );
  return (
    <th
      scope="col"
      className={` px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer`}
      key={index}
    >
      <div className={filter ? "flex items-center" : "flex"}>
        {/* eslint-disable-next-line*/}
        <a
          className="group inline-flex  items-center min-w-max"
          onClick={sortable ? () => handleSortingChange(dataIndex) : null}
        >
          {title}
          {sortable && (
            <span className=" opacity-0 ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200 group-hover:opacity-100 group-focus:visible focus:visited: hover:opacity-100 transition-all">
              <img
                className="h-5 w-5"
                src={order === "asc" ? sortDown : sortUp}
                alt="sort arrow"
              />
            </span>
          )}
        </a>
        {filter && (
          <FilterIcon
            onFilter={setFilters}
            accessor={dataIndex}
            filterData={filterData}
          ></FilterIcon>
        )}
      </div>
    </th>
  );
}
function TCell({ children, className, ...other }) {
  return (
    <td className={className} {...other}>
      {children}
    </td>
  );
}

function THead({ children }) {
  return <thead className="bg-gray-50">{children}</thead>;
}

function TBody({ children }) {
  return (
    <tbody className="bg-white divide-y divide-gray-200">{children}</tbody>
  );
}
