import { useState, useEffect } from "react";
import { Page, EmptyState, Grid, Button, Space } from "../../components";
import { getStores } from "../../utils/services";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Stores() {
  const dispatch = useDispatch();
  const [stores, setStores] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const source = Axios.CancelToken.source();
    getStores(source, 1, dispatch).then(({ results: stores, next }) => {
      let result = stores.map(
        ({ points, store: { id, name, logo_src, slug } }) => {
          return {
            id,
            slug,
            title: name,
            text: `${points} koinz`,
            image: logo_src,
          };
        }
      );
      setNext(next);
      setStores(result ?? []);
      setSource(source);
      setLoading(false);
    });
    return () => {
      source.cancel("component got unmounted");
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      setStores([]);
    };
  }, []);
  const [loading, setLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(null);
  const [source, setSource] = useState(null);

  const history = useHistory();

  const handleClick = async () => {
    setInnerLoading(true);

    let set = new Set(stores);

    const { results, next } = await getStores(source, page + 1, dispatch);
    results.forEach(({ points, store: { id, name, logo_src } }) => {
      set.add({
        id,
        title: name,
        text: points,
        image: logo_src,
      });
    });
    let result = Array.from(set);
    setPage(page + 1);
    setNext(next);
    setStores(result);
    setInnerLoading(false);
  };

  const selectStore = (slug) => {
    history.push(`/stores/${slug}`);
  };

  return (
    <>
      <Page
        title={t("stores.myStores")}
        subtitle={t("stores.storesMsg")}
        loading={loading}
        notification={true}
      >
        {stores.length === 0 ? (
          <EmptyState text={t("stores.NoStore")} />
        ) : (
          <>
            <Grid
              items={stores}
              onClick={(slug) => {
                selectStore(slug);
              }}
            />
            <Space vertical="4">
              <div className="w-full flex items-center justify-center">
                <div
                  className={`${
                    ["", null, undefined].includes(next) && "hidden"
                  }`}
                  onClick={handleClick}
                >
                  <Button disabled={innerLoading} loading={innerLoading}>
                    {t("stores.showMore")}
                  </Button>
                </div>
              </div>
            </Space>
          </>
        )}
      </Page>
    </>
  );
}
