const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "saveUser":
      return {
        ...state,
        ...action.payload,
      };
    case "deleteUser":
      return {};
    default:
      return state;
  }
}
