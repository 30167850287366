import empty from "../../assets/images/empty.svg";
function EmptyState({ text, title }) {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <h1 className="text-xl font-hairline">{title}</h1>
      <p className="text-base">{text}</p>
      <img src={empty} alt="erreur " className="w-4/5 h-4/5" />

    </div>
  );
}

export default EmptyState;
