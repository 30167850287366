import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { setNewPassword, setNewPasswordToken } from "../../utils/services";
import { Banner } from "../common/Banner";
import { Button } from "../common/Button";
import { Form } from "../common/Form";
import { InputText } from "../common/InputText";
import { Space } from "../common/Space";
import { useTranslation } from "react-i18next";

export function RecoverStepfour({ phone, confirmationCode, token }) {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" }); //form validation
  const { t } = useTranslation();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const onSubmit = async (data) => {
    if (token) {
      setLoading(true);
      data = {
        token,
        password: data.new_password,
        email: "amine.benk27@gmail.com",
      };
      try {
        await setNewPasswordToken(data, (x) => {
          return t(x);
        });
        setLoading(false);
        history.push("/");
      } catch (err) {
        setError(err?.message);
        setLoading(false);
      }
    } else {
      data = {
        phone_number: phone,
        code: confirmationCode,
        new_password: data.new_password,
      };
      try {
        setLoading(true);
        await setNewPassword(data, (x) => {
          return t(x);
        });
        setLoading(false);
        history.push("/");
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    }
  };
  return (
    <>
      <Space top="2">
        {error && <Banner title={t("commons.error")} message={error} />}
      </Space>
      <Form
        className="flex flex-col flex-auto"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col md:flex-row flex-auto justify-center items-center px-4 sm:py-8">
          <InputText
            label={t("auth.password")}
            register={register}
            name="new_password"
            required
            type="password"
            className="w-full md:mr-4"
            pattern={{
              value:
                /^(?=.*[A-Z])(?=.*[()[\]{}|\\~!@#$%^&*_\-+=;:,<>./?])(?=.*\d).*$/,
              message: t("auth.strongPassword"),
            }}
            minLength={8}
            error={
              errors?.new_password
                ? ["manual", "pattern"].includes(errors?.new_password?.type)
                  ? errors?.new_password?.message
                  : errors?.new_password?.type === "minLength"
                  ? t("errorMessage.errorPassword")
                  : t("auth.required")
                : ""
            }
          />
          <InputText
            label={t("auth.confirmPassword")}
            register={register}
            name="confirm_password"
            required
            type="password"
            className="w-full md:ml-4"
            validate={(value) =>
              value === getValues("new_password") ||
              t("errorMessage.differentPassword")
            }
            error={
              errors?.confirm_password
                ? ["manual", "validate"].includes(
                    errors?.confirm_password?.type
                  )
                  ? errors?.confirm_password?.message
                  : t("auth.required")
                : ""
            }
          />
        </div>
        <div className="flex flex-col h-auto sm:flex-row sm:justify-end sm:items-center">
          <Button
            fullWidth={false}
            type="secondary"
            responsiveFull={true}
            space
            onClick={() => history.push("/")}
          >
            {t("auth.backHome")}
          </Button>
          <Button
            fullWidth={false}
            responsiveFull={true}
            space
            loading={loading}
            disabled={Object.keys(errors).length !== 0}
          >
            {t("auth.changePassword")}
          </Button>
        </div>
      </Form>
    </>
  );
}
