import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Text } from "./Text";
import { ReactComponent as Close } from "../../assets/images/close.svg";

export function ConfirmationAlert({ toConfirm = "", value }) {
  const [show, setShow] = useState(true);
  const history = useHistory();
  const { t } = useTranslation();
  return (
    show && (
      <div className=" bg-alert p-1 border-b border-white">
        <div className="flex items-center">
          <div className="ml-3 flex items-center flex-wrap">
            <Text weight="light" size="base" className="text-sm  font-light ">
              {toConfirm === "email"
                ? t("auth.yourEmail")
                : t("auth.yourPhone")}
              <p className="mx-1 underline inline rtl:ltr-text">{value}</p>{" "}
              {t("auth.notValidated")}
            </Text>
            <button
              type="button"
              className="rounded  ml-2 rtl:mr-2 p-1.5 text-sm  border  border-alertOutline "
              onClick={() => {
                history.push({
                  pathname: "/confirm-account",
                  state: {
                    toConfirm,
                  },
                });
              }}
            >
              {t("auth.confirm")}
            </button>
          </div>
          <div className="ltr:ml-auto rtl:mr-auto">
            <div className="-mx-0.5 -my-1.5">
              <button
                type="button"
                className="inline-flex rounded-md bg-yellow-50 mr-1 rtl:ml-1 p-1 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
                onClick={() => setShow(false)}
              >
                <span className="sr-only">{t("close")}</span>
                <Close />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
function AccountConfirmationWrapper() {
  const user = useSelector((state) => state.user);
  const emailConfirmation =
    user?.confirmation?.last_email !== null &&
    !user?.confirmation?.email_confirmed;
  const phoneConfirmation =
    user?.confirmation?.last_phone_number !== null &&
    !user?.confirmation?.phone_confirmed;

  return (
    <>
      {emailConfirmation && (
        <ConfirmationAlert
          toConfirm="email"
          value={user?.confirmation?.last_email}
        ></ConfirmationAlert>
      )}
      {phoneConfirmation && (
        <ConfirmationAlert
          toConfirm="phone"
          value={user?.confirmation?.last_phone_number}
        ></ConfirmationAlert>
      )}
    </>
  );
}

export default AccountConfirmationWrapper;
