import { uploadMediaFiles } from "../../api/";

export const uploadFile = async (file) => {
  var formData = new FormData();
  formData.append("file", file);
  formData.append("name", file.name);
  formData.append("file-type", "media");

  return await uploadMediaFiles(formData);
};
