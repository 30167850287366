import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Listbox, Transition } from "@headlessui/react";

const languages = [
  {
    id: 1,
    name: "FR",
    avatar: "https://flagcdn.com/w320/fr.png",
  },
  {
    id: 2,
    name: "EN",
    avatar: "https://flagcdn.com/w320/gb.png",
  },
  {
    id: 3,
    name: "AR",
    avatar: "https://flagcdn.com/w320/dz.png",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FlaggedSelect() {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(languages[2]);

  let selectedLanguage = useSelector((state) => state.language).language;
  useEffect(() => {
    const index = languages.findIndex(
      (f) => f.name === selectedLanguage.toUpperCase()
    );
    setSelected(languages[index]);
  }, [selectedLanguage]);
  return (
    <Listbox
      value={selected}
      onChange={(value) => {
        setSelected(value);
        dispatch({ type: "changeLanguage", preload: value.name.toLowerCase() });
      }}
    >
      {({ open }) => (
        <>
          <div className="relative ">
            <Listbox.Button className="relative w-full rtl:ltr-text cursor-default rounded-md bg-white py-1.5 pl-3 pr-2 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <img
                  src={selected.avatar}
                  alt=""
                  className="h-5 w-5 flex-shrink-0 rounded-full"
                />
                <span className="ml-3 block truncate">{selected.name}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2"></span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {languages.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-kio-500 text-white" : "text-gray-900",
                        "relative rtl:ltr-text cursor-default select-none py-2 pl-3 pr-1"
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img
                            src={person.avatar}
                            alt=""
                            className="h-5 w-5 flex-shrink-0 rounded-full"
                          />
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {person.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          ></span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
