import Cookies from "js-cookie";
import {
  changePassword,
  getUserDevices,
  getUserInfo,
  setAuthToken, // eslint-disable-line
  updateUserInfo,
} from "../../api/";
import { saveUser, deleteUser } from "../../redux/actions";

export const getUserInformations = async (dispatch) => {
  try {
    const user = await getUserInfo();
    let aux = JSON.parse(user);
    dispatch(saveUser(aux));
    return aux;
  } catch (err) {
    throw err;
  }
};

export const updateUserInformations = async (
  dispatch,
  myData,
  t = () => {}
) => {
  try {
    if (myData?.phone_number && myData?.phone_number[0] !== "+")
      myData["phone_number"] = "+" + myData?.phone_number;
    const res = await updateUserInfo(myData);

    if (res?.status === 200 || res?.status === 201) {
      let aux = JSON.parse(JSON.stringify(res?.data));
      dispatch(saveUser(aux));
      return aux;
    } else if (res?.status === 400) {
      if (res?.data?.username) {
        if (
          res?.data?.username[0] === "user with this username already exists."
        )
          throw Error(t("profil.userNameExist"));
        else if (
          res?.data?.username[0] ===
          "Enter a valid username. This value may contain only letters, numbers, and @/./+/-/_ characters."
        )
          throw Error(t("errorMessage.errorUsername"));
      } else if (res?.data?.phone_number) {
        if (
          res?.data?.phone_number[0] ===
          "user with this phone number already exists."
        )
          throw Error(t("errorMessage.usedNumber"));
        else if (
          res?.data?.phone_number[0] ===
          "Phone number must be entered in the format: '+999999999999'. Up to 15 digits allowed."
        ) {
          throw Error(t("errorMessage.invalidNumber"));
        }
      } else if (res?.data?.email) throw Error(t("profil.emailExist"));
      else if (res.data["Details "].includes("Phone number incorrect.")) {
        throw Error(t("errorMessage.incorrectPhone"));
      }
    }
  } catch (err) {
    throw err;
  }
};

export const updateUserPassword = async (data, t = () => {}) => {
  try {
    let res = await changePassword(data);
    if (res?.status === 200 || res?.status === 201) {
      return;
    } else if (res?.status === 400) {
      throw Error(t("errorMessage.invalidPassword"));
    }
  } catch (err) {
    throw err;
  }
};

export const userDevices = async () => {
  try {
    let res = await getUserDevices();
    if (res?.status === 200) return res.data;
    else return [];
  } catch (err) {
    throw err;
  }
};

export const checkUserConfirmation = async (user) => {
  try {
    let res = await getUserDevices();
    if (res?.status === 200) {
      if (res?.data?.phone_confirmed === false && user?.phone_number !== null) {
        return "phone";
      } else if (res?.data?.email_confirmed === false && user?.email !== null)
        return "email";
      else return;
    }
  } catch (err) {
    throw err;
  }
};

export const logout = (history, dispatch) => {
  dispatch(deleteUser());
  localStorage.removeItem("refresh_token");
  Cookies.set("token", "");
  history.push("/");
};
