import {
  Page,
  Table,
  Pagination,
  Badge,
  EmptyState,
  ErrorPage,
} from "../../components";
import { useState, useEffect } from "react";
import axios from "axios";
import { getOrders } from "../../utils/services";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function Orders() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState(1);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [innerLoading, setInnerLoading] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const navigateToOrdersDetails = (item) => {
    history.push(`/orders/${item.id}`);
  };
  useEffect(() => {
    const source = axios.CancelToken.source();
    setInnerLoading(true);
    getOrders(currentPage, source)
      .then((res) => {
        if (res === "cancelled") return;
        if (res === "network error") setNetworkError(true);
        else {
          setLoading(false);
          setInnerLoading(false);
          setOrders(res?.results);
          setPageNumbers(res?.count > 20 ? Math.ceil(res?.count / 20) : 1);
        }
      })
      .catch((error) => console.error(error));
    return () => {
      source.cancel();
    };
  }, [currentPage]);

  const columns = [
    {
      title: `${t("orders.numOrder")}`,
      dataIndex: "sequence",
    },
    {
      title: `${t("orders.date")}`,
      dataIndex: "created",
      render: ({ created }) => {
        return (
          <>
            {new Intl.DateTimeFormat("fr-FR", {
              year: "numeric",
              month: "long",
              day: "2-digit",
              hourCycle: "h24",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }).format(Date.parse(created))}
          </>
        );
      },
      sortable: true,
    },
    {
      title: `${t("orders.store")}`,
      dataIndex: "store",
      filter: true,
    },
    {
      title: `${t("orders.payment")}`,
      dataIndex: "payment",
      render: ({ payment }) => {
        switch (payment) {
          case "PAID":
            return <Badge status="success"> {t("orders.paid")} </Badge>;
          case "PENDING":
            return <Badge status="warning"> {t("orders.onHold")}</Badge>;
          case "PARTIALLY_PAID":
            return <Badge status="warning"> {t("orders.partiallyPaid")}</Badge>;
          default:
            break;
        }
      },

      filter: true,
    },
    {
      title: `${t("orders.total")}`,
      dataIndex: "gross_total",
      render: ({ gross_total, gross_total_currency }) => (
        <>
          {gross_total} {gross_total_currency}
        </>
      ),
      sortable: true,
    },
  ];

  return (
    <>
      {networkError === true ? (
        <ErrorPage />
      ) : (
        <Page
          title={t("orders.orders")}
          subtitle={t("orders.welcome")}
          loading={loading}
          notification={true}
        >
          {!orders?.length ? (
            <EmptyState text={t("orders.AucuneCommandeText")} />
          ) : (
            <>
              <Table
                dataSource={orders ?? []}
                columns={columns}
                innerLoading={innerLoading}
                onClick={navigateToOrdersDetails}
              />
              {pageNumbers !== 1 && (
                <Pagination
                  currentPage={currentPage}
                  pageNumbers={pageNumbers}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </>
          )}
        </Page>
      )}
    </>
  );
}
